import { useMutation, useQuery } from '@tanstack/react-query';
import * as service from '../services';
import { useEditBaseTableStore } from '../EditBaseTable.store';
import { useCustosGeraisTabela } from './useCustosGeraisTabela';
import { NotificationMessage } from '../../../../../../components';

export const usePriceApply = () => {
    const {
        priceApplyList,
        setEditBaseTableState,
    } = useEditBaseTableStore();

    const { handleGetCustosGeraisTabelaData } = useCustosGeraisTabela()

    const priceApplySuccess = () => {
        NotificationMessage(
            'success',
            5000,
            'Preço aplicado com sucesso',
        );
        handleGetCustosGeraisTabelaData()
    }

    const mutation = useMutation({
        mutationFn: () => service.priceApply(priceApplyList),
        onSuccess: priceApplySuccess,
    });

    const mutationAll = useMutation({
        mutationFn: () => service.selectAllAplicarPrecos('NACIONAL'),
        onSuccess: priceApplySuccess,
    });

    return {
        handleConfirmPriceApply: mutation.mutateAsync,
        handleConfirmPriceApplyAll: mutationAll.mutateAsync,
    };
};
