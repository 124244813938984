import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import * as service from '../services';

export const useConsultComercialTableProductList = () => {
    const [ consultComercialTableParams, setConsultComercialTableParams ] = useState({page: 0, size: 20, idTabelaComercial: null})
    // const {
    //     comercialTableItem,
    //     setOpenModalProductCostComercialTable,
    // } = useManageComercialTableStore();

    const getComercialTableProductList = async (params) => {
        if (params.idTabelaComercial) {
            const resp = await service.getProdutoComercialList(params);
            return resp;
        }
    };

    const {
        isLoading, isError, data, error, isFetching, refetch,
    } = useQuery(
        {
            queryKey: ['consult-comercial-table-product-list', consultComercialTableParams],
            queryFn: () => getComercialTableProductList(consultComercialTableParams),
            retry: false,
        },
    );

    return {
        isLoading,
        isError,
        consultComercialTableProductData: data,
        error,
        isFetching,
        consultComercialTableParams,
        setConsultComercialTableParams,
    };
};
