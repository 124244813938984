import React from 'react';
import { Alert, Button, Checkbox } from 'rsuite';
import { LoadingMenu } from '../../../../../../../../../components/LoadingSpinner';
import SelectPill from '../../../../../../../../../components/SelectPill';
import arrowRightIcon from '../../../../../../../../../assets/icons/icon_arrow-right-gray.svg';
import styles from './Channel.module.scss';
import { useCanaisFilter } from '../../../../hooks/useCanaisFilter';
import { useSubCanaisFilter } from '../../../../hooks/useSubCanaisFilter';
import { useCreateComercialTableStore } from '../../../../CreateComercialTable.store';

const locale = {
    noResultsText: 'Nenhum item encontrado',
    searchPlaceholder: 'Buscar item',
};

export const Channel = () => {
    const { canaisData } = useCanaisFilter();
    const { subCanaisData } = useSubCanaisFilter()

    const { createComercialTableBody, setCreateComercialTableState } = useCreateComercialTableStore();

    const handleSelectCanal = (name: string, value: string) => {
        const obj = {...createComercialTableBody, [name]: value}
        setCreateComercialTableState('createComercialTableBody', obj)
    }

    const handleClean = (name: string) => {
        const obj = {...createComercialTableBody, [name]: ''}
        setCreateComercialTableState('createComercialTableBody', obj)
    }

    return (
        <div className={styles['select-pill-wrapper']}>
            <SelectPill
                id="select-picker-pill"
                placeholder="Selecione uma opção"
                name="second"
                searchable={false}
                value={createComercialTableBody.canal}
                data={canaisData}
                onSelect={(value) => handleSelectCanal('canal', value)}
                onClean={() => handleClean('canal')}
                locale={locale}
                renderMenu={(menu) => (
                    <span>
                        <LoadingMenu area="panel-filter" />
                        {menu}
                    </span>
                )}
            />
            <img
                src={arrowRightIcon}
                alt=""
                className={styles['arrow-right-icon']}
            />
            <SelectPill
                id="select-picker-pill"
                placeholder="Canais selecionadas"
                name="second"
                searchable={false}
                value={createComercialTableBody.subCanal}
                data={subCanaisData}
                onSelect={(value) => handleSelectCanal('subCanal', value)}
                onClean={() => handleClean('subCanal')}
                locale={locale}
                renderMenu={(menu) => (
                    <span>
                        <LoadingMenu area="panel-filter" />
                        {menu}
                    </span>
                )}
            />
        </div>
    );
};
