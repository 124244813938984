import { trackPromise } from 'react-promise-tracker';
import api from '../../../../../utils/API';

const URL = process.env.REACT_APP_IPA_PRICE_TABLES_MANAGER_URL

// ########## - CONSULTAR TABELA COMERCIAL - ##########

export const getProdutoComercialList = (data) => trackPromise(
    api({
        url: `${URL}/rascunho-produto-comercial`,
        method: 'GET',
        expectToken: true,
        params: {
            page: data.page,
            size: data.size,
            idTabelaComercial: data.idTabelaComercial
        },
    }).then((res) => res),
    'consult-comercial-table'
);
