import { trackPromise } from 'react-promise-tracker';
import { Alert } from 'rsuite';
import { api } from '../../../../../../lib';
import { ProductsSegmentation } from './schema';

export const GET_loadProductsSegmentation = async (): Promise<ProductsSegmentation> => {
    const response = await trackPromise(
        api.get<ProductsSegmentation>('/setup/segmentation', {
            expectToken: true,
        }),
        'ipa/setup-form/segmentacao-de-produtos',
    );
    return response.data;
};

export const PATCH_updateProductsSegmentation = async (data: Partial<ProductsSegmentation>): Promise<void> => {
    try {
        const response = await trackPromise(
            api.patch<void>('/setup/segmentation', data, {
                expectToken: true,
            }),
            'ipa/setup-form/segmentacao-de-produtos',
        );
        return response.data;
    } catch {
        throw new Error('Erro ao atualizar segmentação de produtos');
    } finally {
        Alert.success('Segmentação de produtos atualizada com sucesso');
    }
};
