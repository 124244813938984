/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import React, { ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { CheckPicker as RSPicker } from 'rsuite';
import { ItemDataType } from 'rsuite/lib/@types/common';
import { CheckPickerProps as RSPickerProps } from 'rsuite/lib/CheckPicker';
import { useSingleModal } from '../../hooks';
import { FilterMenuWithPasteValuesButton, FilterPasteModal, FilterSelectAll, FilterSelectAllProps } from '../FilterSection';
import styles from './CheckPicker.module.scss';
import { customRenderValue } from './customRenderValue';

export type CheckPickerProps = RSPickerProps & {
    textLoading?: string;
    textSearch?: string;
    isLoading?: boolean;
    selectAll?: FilterSelectAllProps;
    pasteValues?: boolean;
    onConfirmPasteValues?: (query: string) => void;
    modalText?: string;
};

export const CheckPicker = ({
    data = [],
    value = [],
    search,
    placeholder,
    selectAll,
    textLoading = 'Carregando...',
    textSearch = 'Pesquisar',
    searchable = true,
    pasteValues = false,
    isLoading = false,
    modalText,
    onSearch,
    renderValue,
    renderMenu,
    onConfirmPasteValues,
    ...props
}: CheckPickerProps) => {
    const [query, setQuery] = useState(search);
    const [modalQuery, setModalQuery] = useState('');
    const [isOpenModal, { open: openModal, close: closeModal }] = useSingleModal();

    const handleSearch = useCallback(
        (query: string, event: SyntheticEvent<any>) => {
            setQuery(query);
            onSearch?.(query, event);
        },
        [onSearch],
    );

    const handleConfirmPasteValues = useCallback(
        (query: string) => {
            onConfirmPasteValues?.(query);
            closeModal();
            setModalQuery('');
        },
        [onConfirmPasteValues, closeModal],
    );

    const renderLocale = useMemo(
        () => ({
            noResultsText: isLoading ? textLoading : 'Nenhum item encontrado',
            searchPlaceholder: textSearch,
        }),
        [isLoading, textLoading, textSearch],
    );

    const renderSearchable = useMemo(() => {
        return pasteValues ? false : searchable;
    }, [searchable, pasteValues]);

    const renderExtraFooter = useCallback(() => {
        const isChecked = data.length === value.length && !!value.length;
        return selectAll ? <FilterSelectAll checked={isChecked} {...selectAll} /> : null;
    }, [data.length, selectAll, value.length]);

    const renderPlaceholder = useCallback(
        (value: any[], item: ItemDataType | ItemDataType[], selectedElement: React.ReactNode): React.ReactNode => {
            if (renderValue) return renderValue(value, item, selectedElement);
            return customRenderValue(value, placeholder);
        },
        [renderValue, placeholder],
    );

    const renderCustomMenu = useCallback(
        (menu: ReactNode) => {
            if (renderMenu) return renderMenu(menu);
            if (pasteValues) {
                return <FilterMenuWithPasteValuesButton menu={menu} search={query} handleSearch={handleSearch} handleOpenModal={openModal} />;
            }
        },
        [pasteValues, query, handleSearch, renderMenu, openModal],
    );

    useEffect(() => setQuery(search), [search]);

    return (
        <>
            <RSPicker
                {...props}
                className={classNames(styles.wrapper)}
                data={isLoading ? [] : data}
                value={value}
                placeholder={placeholder}
                locale={renderLocale}
                searchable={renderSearchable}
                renderExtraFooter={renderExtraFooter}
                renderValue={renderPlaceholder}
                onSearch={handleSearch}
                renderMenu={renderCustomMenu}
            />
            {pasteValues && (
                <FilterPasteModal
                    show={isOpenModal}
                    query={modalQuery}
                    onHide={closeModal}
                    onSearch={setModalQuery}
                    onConfirm={handleConfirmPasteValues}
                    modalText={modalText}
                />
            )}
        </>
    );
};
