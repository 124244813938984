import React from 'react';
import { SET_FILTER_CACHE_BY_KEY, SET_FILTER_DATA_BY_KEY, SET_FILTERS_SEARCH_BY_KEY, SET_FILTERS_VALUE_BY_KEY } from '../../../reducers';
import { CheckPicker } from '../../CheckPicker';
import { FilterPlaceholder } from '../../FilterPlaceholder';
import { listFamilies } from '../../FilterSection/services';
import { UseFilterQueryProps, useFilterQueryWithReducer } from '../useFilterQueryWithReducer';

const DATA_KEY = 'productFamilyIds' as const;

const options: UseFilterQueryProps<typeof DATA_KEY> = {
    dataKey: DATA_KEY,
    loadFn: listFamilies,
    selectors: {
        data: (state) => state.gerenciadorPrecosReducer.filters.data.productFamilyIds || [],
        cache: (state) => state.gerenciadorPrecosReducer.filters.cache.productFamilyIds || [],
        value: (state) => state.gerenciadorPrecosReducer.filters.values.productFamilyIds || [],
        query: (state) => state.gerenciadorPrecosReducer.filters.search.productFamilyIds || '',
    },
    actions: {
        setData: SET_FILTER_DATA_BY_KEY,
        setCache: SET_FILTER_CACHE_BY_KEY,
        setValue: SET_FILTERS_VALUE_BY_KEY,
        setQuery: SET_FILTERS_SEARCH_BY_KEY,
    },
};

export const FamillyPill = () => {
    const filter = useFilterQueryWithReducer(options);

    return (
        <CheckPicker
            {...filter}
            pasteValues
            // @ts-expect-error
            placeholder={<FilterPlaceholder dataKey={DATA_KEY} placeholder="Família" />}
            modalText="Escreva ou cole uma lista de códigos de família separados por espaço ou pelos caracteres , ; |  "
        />
    );
};
