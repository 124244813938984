import { create } from 'zustand';
import {
    CustoFabricacaoBodyType,
    CustosGeraisTabelaBodyType,
    CustosProdutoBodyType,
    EditBaseTableItemDataType,
    PriceAnalysisModalTableType,
    ProdutoDataType
} from './types';

type State = {
    productItemData: EditBaseTableItemDataType;
    editBaseTableListContent: ProdutoDataType[],
    productItemSelectedList: ProdutoDataType[],
    openModalEditOtherCosts: boolean,
    openModalPriceAnalysis: boolean,
    openModalEditGeneralCosts: boolean,
    openModalEditConfirmation: boolean,
    custoFabricacaoBody: CustoFabricacaoBodyType,
    custosProdutoBody: CustosProdutoBodyType,
    custosGeraisTabelaBody: CustosGeraisTabelaBodyType,
    priceApplyList: number[],
    priceAnalysisTableData: PriceAnalysisModalTableType[],
    selectAllTable: boolean,
}

type Actions = {
    setEditBaseTableState: (name: string, data: any) => void,
    setOpenModalEditOtherCosts: (open: boolean, data) => void,
    setOpenModalEditGeneralCosts: (open: boolean, data) => void,
    resetEditBaseTableStore: (name: string) => void,
    reset: () => void,
}

const initialState: State = {
    productItemData: {
        id: 0,
        criadoEm: '',
        atualizadoEm: '',
        versao: 0,
        produtoBase: {
            id: 0,
            criadoEm: '',
            atualizadoEm: '',
            versao: 0,
            produto: {
                id: null,
                criadoEm: '',
                atualizadoEm: '',
                versao: null,
                idCliente: null,
                data: '',
                codigoInternoProduto: '',
                tipoCodigoProduto: '',
                codigoEmbalagemProduto: '',
                codigoUnidadeProduto: '',
                codigoBarras: '',
                tipoCodigoBarras: '',
                descricaoProduto: '',
                status: '',
                unidadesPorEmbalagem: null,
                tamanhoEmbalagem: null,
                unidadeTamanhoEmbalagem: '',
                tipoEmbalagem: '',
                unidadeTipoEmbalagem: '',
                idFamiliaProduto: '',
                produtoMasterFamilia: false,
                marca: '',
                categoriaNivel1: '',
                categoriaNivel2: '',
                categoriaNivel3: '',
                categoriaNivel4: '',
                categoriaNivel5: '',
                categoriaNivel6: '',
                categoriaNivel7: '',
                ultimaAlteracao: '',
                codigoPrincipal: null,
                tierProduto: '',
            },
            parametros: {
                custoFabricacao: 0,
                precoMedio: {
                    valorAtual: 0,
                    ultimaDiferencaPercentual: 0,
                },
                novoPrecoTabela: 0,
            },
            tabela: {
                id: 0,
                criadoEm: '',
                atualizadoEm: '',
                versao: 0,
                idCliente: 0,
                uf: 'NACIONAL',
            },
            impostos: {
                ipi: 0,
                icms: 0,
                pis: 0,
                cofins: 0,
                outros: 0,
            },
            custosVenda: {
                freteMedio: 0,
                comissao: 0,
                trocasOuDevolucao: 0,
                gratificacao: 0,
                outros: 0,
            },
            despesaVenda: {
                comerciais: 0,
                administrativas: 0,
                outras: 0,
            },
        },
    },
    custoFabricacaoBody: {
        idRascunhoProduto: 0,
        custoFabricacao: 0,
        precoMedio: 0,
        novoPrecoTabela: 0
    },
    custosProdutoBody: {
        codigoRascunhosProduto: [0],
        impostos: {
            ipi: 0,
            icms: 0,
            pis: 0,
            cofins: 0,
            outros: 0
        },
        custos: {
            freteMedio: 0,
            comissao: 0,
            trocasOuDevolucao: 0,
            gratificacao: 0,
            outros: 0
        },
        despesas: {
            comerciais: 0,
            administrativas: 0,
            outras: 0
        }
    },
    custosGeraisTabelaBody: {
        uf: 'NACIONAL',
        impostos: {
            ipi: 0,
            icms: 0,
            pis: 0,
            cofins: 0,
            outros: 0
        },
        custos: {
            freteMedio: 0,
            comissao: 0,
            trocasOuDevolucao: 0,
            gratificacao: 0,
            outros: 0
        },
        despesaVenda: {
            comerciais: 0,
            administrativas: 0,
            outras: 0
        }
    },
    editBaseTableListContent: [],
    productItemSelectedList: [],
    priceApplyList: [],
    priceAnalysisTableData: [],
    openModalEditOtherCosts: false,
    openModalEditGeneralCosts: false,
    openModalPriceAnalysis: false,
    openModalEditConfirmation: false,
    selectAllTable: false,
};

export const useEditBaseTableStore = create<State & Actions>((set) => ({
    ...initialState,
    setEditBaseTableState: (name, data) => {
        set(() => ({
            [name]: data,
        }));
    },
    setOpenModalEditOtherCosts: (open: boolean, data: EditBaseTableItemDataType) => {
        set(() => ({
            openModalEditOtherCosts: open,
            productItemData: data || initialState.productItemData,
            custosProdutoBody: {
                codigoRascunhosProduto: [data?.id || 0],
                impostos: {
                    ipi: data?.impostos.ipi,
                    icms: data?.impostos.icms,
                    pis: data?.impostos.pis,
                    cofins: data?.impostos.cofins,
                    outros: data?.impostos.outros
                },
                custos: {
                    freteMedio: data?.custosVenda.freteMedio,
                    comissao: data?.custosVenda.comissao,
                    trocasOuDevolucao: data?.custosVenda.trocasOuDevolucao,
                    gratificacao: data?.custosVenda.gratificacao,
                    outros: data?.custosVenda.outros
                },
                despesas: {
                    comerciais: data?.despesaVenda.comerciais,
                    administrativas: data?.despesaVenda.administrativas,
                    outras: data?.despesaVenda.outras
                }
            },
        }));
    },
    setOpenModalEditGeneralCosts: (open: boolean, data: EditBaseTableItemDataType) => {
        set(() => ({
            openModalEditGeneralCosts: open,
        }));
    },
    setOpenModalPriceAnalysis: (open: boolean, data: EditBaseTableItemDataType) => {
        set(() => ({
            openModalPriceAnalysis: open,
            priceAnalysisTableData: [
                {
                    name: 'Tabela atual',
                    price: data.tabelaAtual.valorTabelaAtual,
                    diff: null
                },
                {
                    name: 'Preço médio atual',
                    price: data.parametros.precoMedio.valorAtual,
                    diff: data.parametros.precoMedio.ultimaDiferencaPercentual * 100
                },
                {
                    name: 'Novo preço de tabela',
                    price: data.parametros.novoPrecoTabela.valorNovo,
                    diff: data.parametros.novoPrecoTabela.diferencaPercentual
                },
                {
                    name: 'Preço médio projetado',
                    price: data.projecao.precoMedio.valorProjetado,
                    diff: data.projecao.precoMedio.variacaoPercentual
                },
            ]
        }));
    },
    resetEditBaseTableStore: (name: string) => {
        set(() => ({ [name]: initialState[name]}));
    },
    reset: () => {
        set(initialState);
    },
}));
