import classNames from 'classnames';
import React, { ComponentProps, forwardRef, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GerenciadorPrecos } from '../../../../../@types/GerenciadorPrecos';
import {
    REMOVE_GERENCIADOR_EXPANDED_ROW_OPTION,
    RESET_GERENCIADOR_EXPANDED_ROW,
    selectorExpandedRowKeyOptions
} from '../../../../../reducers/gerenciadorPrecos/expandedRowKey';
import { useFarma } from '../../../../Administrador/Produtos/Components/SetupFarma/hooks/useFarma';
import { ExpandedRowHeight } from '../../utils';
import { ExpandedRowCompetitors } from '../ExpandedRowCompetitors';
import { ExpandedRowCompetitorsOffer } from '../ExpandedRowCompetitorsOffer';
import ExpandedRowOffer from '../ExpandedRowOffer/ExpandedRowOffer';
import { ExpandedRowWholesale } from '../ExpandedRowWholesale';
import styles from './ExpandedRowTable.module.scss';
type RowData = GerenciadorPrecos.RowData;

export type ExpandedRowTableProps = Omit<ComponentProps<'table'>, 'children'> & {
    rowData?: RowData;
};

const ExpandedRowTable = memo(
    forwardRef<HTMLTableElement, ExpandedRowTableProps>(({ rowData, className, ...props }, ref) => {
        const dispatch = useDispatch();
        const { configFarma } = useFarma();


        const options = useSelector(selectorExpandedRowKeyOptions);
        const isExpanded = useMemo(() => options.length > 1, [options]);

        const isRowExpanded = useCallback((option: (typeof options)[number]) => options.includes(option), [options]);

        useEffect(() => {
            if (!isExpanded) dispatch(RESET_GERENCIADOR_EXPANDED_ROW());

            if (options.length === 2 && options.includes('HEADER') && options.includes('OFFERPRICE_COMPETITORS')) {
                dispatch(REMOVE_GERENCIADOR_EXPANDED_ROW_OPTION('OFFERPRICE_COMPETITORS'));
            }

            if (options.length === 3 && options.includes('HEADER') && options.includes('WHOLESALE') && options.includes('OFFERPRICE_COMPETITORS')) {
                dispatch(REMOVE_GERENCIADOR_EXPANDED_ROW_OPTION('OFFERPRICE_COMPETITORS'));
            }

        }, [options, isExpanded, dispatch]);

        const isHeaderAndCompetitors = useMemo(
            () => options.length === 2 && options.includes('HEADER') && options.includes('COMPETITORS'),
            [options]
        );

        if (!isExpanded || !rowData) return null;

        return (
            <table
                {...props}
                data-table-row-is-expanded={isExpanded}
                ref={ref}
                className={classNames(styles['table'], className)}
                style={{ ['--_header-height' as string]: `${ExpandedRowHeight.getRowHeight('HEADER')}px` }}
            >
                {!isHeaderAndCompetitors && (
                    <thead>
                        <tr>
                            {/* blank column to fix layout */}
                            <th style={{ width: '60px' }}></th>
                            <th>Outros preços</th>
                            {/* store column blank to fix layout */}
                            <th style={{ width: '80px' }}></th>
                            {/* pmz column blank to fix layout */}
                            <th style={{ width: '78px' }}></th>
                            {/* margem objetiva column blank to fix layout */}
                            <th style={{ width: '78px' }}></th>
                            <th style={{ width: configFarma?.usePMCForIPAPricing ? '64px' : '52px' }}>Emb..</th>
                            {/* pmc column blank to fix layout */}
                            <th style={{ width: configFarma?.usePMCForIPAPricing ? '124px' : '12px' }}></th>
                            <th style={{ width: '124px' }}>Preço vigente</th>
                            <th style={{ width: '127px' }}>Preço concorrente</th>
                            <th style={{ width: '132px' }}>Preço sugerido</th>
                            <th style={{ width: '116px' }}>CPI</th>
                            <th style={{ width: '116px' }}>Margem</th>
                            <th style={{ width: '140px' }}>Desconto</th>
                            {/* close button column blank to fix layout */}
                            <th style={{ width: '26px' }}></th>
                        </tr>
                    </thead>
                )}
                <tbody>
                    {isRowExpanded('WHOLESALE') && <ExpandedRowWholesale  rowData={rowData} />}
                    {isRowExpanded('OFFER') && <ExpandedRowOffer  rowData={rowData} />}
                    {isRowExpanded('COMPETITORS') && (
                        <tr>
                            <td colSpan={13}>
                                <ExpandedRowCompetitors rowData={rowData} />
                            </td>
                        </tr>
                    )}
                    {isRowExpanded('OFFERPRICE_COMPETITORS') && (
                        <tr>
                            <td colSpan={13}>
                                <ExpandedRowCompetitorsOffer rowData={rowData} />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }),
);

ExpandedRowTable.displayName = 'ExpandedRowTable';

export { ExpandedRowTable };
