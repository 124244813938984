import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { CompetitorDaysData, PriceOption } from '../SetupIPADadosDeConcorrenciatypes';
import { usePriceOptions } from '../hooks/usePriceOptions';
import { ConfigFormProps, FormValues, schema } from '../interfaces';
import { FormFooter } from './FormFooter';
import { FormHeader } from './FormHeader';
import { PriceOptionsSection } from './PriceOptionsSection';
import { PricePreferenceSection } from './PricePreferenceSection';

const ConfigForm = ({ data, createConfig, updateConfig }: ConfigFormProps) => {
    const { control, handleSubmit, setValue, watch } = useForm({
        resolver: zodResolver(schema),
        mode: 'onSubmit',
    });

    const showOtherPrices = watch('price_option');

    const { priceOption, otherPrices, competitorPricePMCToggle, handlePriceOptionChange, handleOtherPricesChange, handleToggleCompetitorPricePMC } =
        usePriceOptions(data, setValue);

    const onSubmit = (formData: FormValues) => {
        const payload = {
            ...formData,
            competitor_data: {
                price_option: priceOption,
                other_prices: priceOption === PriceOption.REGULAR_AND_OTHERS ? otherPrices : undefined,
                limit_in_pmc: competitorPricePMCToggle,
            },
        } as CompetitorDaysData;

        if (formData.id) {
            updateConfig(payload);
        } else {
            createConfig(payload);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
            <FormHeader />
            <PricePreferenceSection control={control} />
            <PriceOptionsSection
                control={control}
                priceOption={priceOption}
                competitorPricePMCToggle={competitorPricePMCToggle}
                handleToggleCompetitorPricePMC={handleToggleCompetitorPricePMC}
                handlePriceOptionChange={handlePriceOptionChange}
                handleOtherPricesChange={handleOtherPricesChange}
                showOtherPrices={showOtherPrices === PriceOption.REGULAR_AND_OTHERS}
            />
            <FormFooter />
        </form>
    );
};

export default ConfigForm;
