import React from 'react';
import styles from './Indicator.module.scss';

export interface IIndicator {
    value?: number;
    className?: string;
    fontSize?: string;
    type?: string;
}

export const Indicator = ({
    value = 0,
    className,
    type = 'percent',
    fontSize = '12px',
}: IIndicator) => {
    const lineHeight = fontSize === '10px' ? '10px' : '';

    if (value.toFixed(1) === '0.0' || value.toFixed(1) === '-0.0') {
        return '--'
    }

    return (
        <div
            className={`${styles[value > 0 ? 'indicator-positive' : 'indicator-negative']} ${className}`}
        >
            <span className={styles['indicator-value']} style={{ fontSize, lineHeight }}>
                {value > 0 ? '+' : null}
                {' '}
                {value?.toFixed(1).replace('.', ',')}
                {' '}
                {type === 'percent' ? '%' : 'pp'}
            </span>
        </div>
);
};
