import Decimal from 'decimal.js';
import { IGerenciadorPrecosDatapoint } from '../@types';

const calcCpi = (
    price: IGerenciadorPrecosDatapoint['price'],
    competitorsPrice: IGerenciadorPrecosDatapoint['competitorsPrice'],
) => {
    if (price === null || competitorsPrice === null || competitorsPrice === 0) return 0;
    return new Decimal(price).div(competitorsPrice).times(100).toDecimalPlaces(2).toNumber();
};

const calcNovaMargem = (
    price: IGerenciadorPrecosDatapoint['price'],
    salesTaxPercentage: IGerenciadorPrecosDatapoint['salesTaxPercentage'],
    expensesPercent: IGerenciadorPrecosDatapoint['expensesPercent'],
    cost: IGerenciadorPrecosDatapoint['cost'],
) => {
    const validPrice = new Decimal(price ?? 0);
    const validSalesTaxPercentage = new Decimal(salesTaxPercentage ?? 0);
    const validExpensesPercent = new Decimal(expensesPercent ?? 0);
    const validCost = new Decimal(cost ?? 0);

    const result = validPrice
        .minus(validPrice.times(validSalesTaxPercentage.plus(validExpensesPercent).div(100)))
        .minus(validCost)
        .div(validPrice)
        .times(100);

    return result.toDecimalPlaces(2).toNumber();
};

const calcPrecoWithCpi = (
    cpi: number | null,
    competitorsPrice: IGerenciadorPrecosDatapoint['competitorsPrice'],
) => {
    if (cpi === null || competitorsPrice === null) return null;
    return new Decimal(cpi).div(100).times(competitorsPrice).toNumber();
};

const calcNovaMargemWithPmz = (
    price: IGerenciadorPrecosDatapoint['price'],
    pmz: IGerenciadorPrecosDatapoint['pmz'],
) => {
    if (price === null || pmz === null) return null;
    return new Decimal(price).minus(pmz).div(price).times(100).toDecimalPlaces(2).toNumber();
};

const calcPrecoWithMargem = (
    novaMargem: IGerenciadorPrecosDatapoint['novaMargem'],
    cost: IGerenciadorPrecosDatapoint['cost'],
    expensesPercent: IGerenciadorPrecosDatapoint['expensesPercent'],
    salesTaxPercentage: IGerenciadorPrecosDatapoint['salesTaxPercentage'],
) => {
    if (
        novaMargem === null ||
        cost === null ||
        expensesPercent === null ||
        salesTaxPercentage === null
    ) {
        return 0;
    }

    const result = new Decimal(cost)
        .div(
            new Decimal(100)
                .minus(new Decimal(novaMargem ?? 0))
                .minus(new Decimal(expensesPercent).plus(salesTaxPercentage)),
        )
        .times(100);

    return result.toDecimalPlaces(2).toNumber();
};

const calcCpiWithMargem = (
    novaMargem: IGerenciadorPrecosDatapoint['novaMargem'],
    price: IGerenciadorPrecosDatapoint['price'],
    competitorsPrice: IGerenciadorPrecosDatapoint['competitorsPrice'],
) => {
    if (novaMargem === null || competitorsPrice === null || price === null) {
        return null;
    }
    return new Decimal(price).div(competitorsPrice).times(100).toDecimalPlaces(2).toNumber();
};

export const calcPriceVariation = (
    price: IGerenciadorPrecosDatapoint['price'],
    retailPrice: IGerenciadorPrecosDatapoint['retailPrice'],
) => {
    if (price === null || retailPrice === null) return null;
    return new Decimal(price).div(retailPrice).minus(1).times(100).toDecimalPlaces(1).toNumber();
};

const calcCpiPriceVariation = (
    novaCompetitividade: IGerenciadorPrecosDatapoint['novaCompetitividade'],
    retailCpi: IGerenciadorPrecosDatapoint['retailCpi'],
) => {
    if (novaCompetitividade === null || novaCompetitividade === undefined || retailCpi === null || retailCpi === undefined) return null;
    return new Decimal(novaCompetitividade).minus(retailCpi).toDecimalPlaces(1).toNumber();
};

const calcMargemVariation = (
    novaMargem: IGerenciadorPrecosDatapoint['novaMargem'],
    newMargin: IGerenciadorPrecosDatapoint['newMargin'],
) => {
    if (novaMargem === null || novaMargem === undefined || newMargin === null) return null;
    return new Decimal(novaMargem).minus(newMargin).toDecimalPlaces(1).toNumber();
};

const calcPrecoWithDiscount = (
    basePrice: number,
    discountPercentage: number
) => {
    if (basePrice === null || discountPercentage === null) return null;
    return new Decimal(basePrice)
        .minus(new Decimal(basePrice).times(discountPercentage).div(100))
        .toDecimalPlaces(2)
        .toNumber();
};

const calcDiscountPercentage = (
    basePrice: number,
    finalPrice: number
) => {
    if (basePrice === null || finalPrice === null) return null;
    return new Decimal(1)
        .minus(new Decimal(finalPrice).div(basePrice))
        .times(100)
        .toDecimalPlaces(2)
        .toNumber();
};

export default {
    novaCompetitividade: calcCpi,
    novaMargem: calcNovaMargem,
    precoWithCpi: calcPrecoWithCpi,
    novaMargemWithPmz: calcNovaMargemWithPmz,
    precoWithMargem: calcPrecoWithMargem,
    cpiWithMargem: calcCpiWithMargem,
    variation: {
        price: calcPriceVariation,
        cpi: calcCpiPriceVariation,
        margem: calcMargemVariation,
    },
    calcPrecoWithDiscount,
    calcDiscountPercentage,
} as const;
