import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as service from '../services';
import { useCreateComercialTableStore } from '../CreateComercialTable.store';

export const useProductFilter = () => {
    const [timer, setTimer] = useState(null)
    const [baseProductData, setBaseProductData] = useState({list: [], cache: [], value: []})
    const [searchProducts, setSearchProducts] = useState({page: 0, size: 1000});

    const {
        createComercialTableBody,
        setCreateComercialTableState,
        setCreateComercialTableCleanFilters,
    } = useCreateComercialTableStore();

    const getBaseProductData = async (params: any) => {
        const res = await service.getBaseProductList(params);
        return res;
    };

    const {
        data,
        isLoading,
        isError,
        isSuccess,
        refetch,
    } = useQuery({
        queryKey: ['base-products', searchProducts],
        queryFn: () => getBaseProductData(searchProducts),
        onSuccess(data) {
            const bodyList = data.data.content.map((item) =>
                ({
                    idProduto: Number(item.id),
                }));
            const list = data.data.content.map((item) =>
                ({
                    label: `${item.produto.codigoInternoProduto} - ${item.produto.descricaoProduto}`,
                    value: item.id
                }));

            const value = data.data.content.map((item) => item.id)
            const produtos = {...createComercialTableBody, produtosBase: bodyList}

            setBaseProductData((prevState) => ({
                ...prevState,
                list,
                cache: list,
                value,
            }));
            setCreateComercialTableState('createComercialTableBody', produtos)
        },
        retry: false,
    });

    const handleAddBaseProduct = (item) => {
        const newList = baseProductData.value;
        const newValue = newList.concat(item.value);
        const product = { idProduto: item.value }
        const bodyNewList = {
            ...createComercialTableBody,
            produtosBase: [...createComercialTableBody.produtosBase, product]
        }

        setBaseProductData((prevState) => ({
            ...prevState,
            value: newValue,
            cache: item,
        }));
        setCreateComercialTableState('createComercialTableBody', bodyNewList)
    };

    const handleRemoveBaseProduct = (item) => {
        const removedCache = baseProductData.cache.filter(
            (i) => i.value !== item.value
        );
        const newValue = baseProductData.value.filter(
            (i) => i !== item.value
        );
        const removeProduct = createComercialTableBody.produtosBase.filter(
            (i) => i.idProduto !== item.value
        );
        const bodyNewList = {
            ...createComercialTableBody,
            produtosBase: removeProduct
        }
        setBaseProductData((prevState) => ({
            ...prevState,
            value: newValue,
            cache: removedCache,
        }));
        setCreateComercialTableState('createComercialTableBody', bodyNewList)
    };

    const handleCleanBaseProduct = () => {
        setBaseProductData((prevState) => ({ ...prevState, value: [], cache: [] }));
    };

    const handleCheckBaseProduct = (item) => {
        const hasValue = baseProductData.value.includes(item.value);
        if (hasValue) {
            handleRemoveBaseProduct(item)
        } else {
            handleAddBaseProduct(item)
        }
    };

    const handleCheckAllBaseProduct = () => {
        const value = baseProductData.list.map((i) => i.value)
        const all = baseProductData.list.length === baseProductData.value.length;
        if (!all) {
            setBaseProductData((prevState) => ({
                ...prevState,
                value,
                cache: baseProductData.list,
            }));
        } else {
            handleCleanBaseProduct();
        }
    };

    return {
        baseProductData,
        isLoadingRegisteredProducts: isLoading,
        isError,
        isSuccess,
        searchProducts,
        refetchRegisteredProducts: refetch,
        setSearchProducts,
        handleCheckBaseProduct,
        handleCheckAllBaseProduct,
        handleCleanBaseProduct
    };
};
