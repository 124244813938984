import React from 'react';
import CurrencyFormat from 'react-currency-format';
import {
    Checkbox,
    InputGroup,
    Table,
    Tooltip,
    Whisper
} from 'rsuite';
import { InputCurrency, LinkButton } from '../../../../../../../components';
import { LoadingSpinerArea } from '../../../../../../../components/LoadingSpinner';
import { Indicator } from '../../../../../../CalculadoraPrevisaoDemanda/Components/Indicator';

import WarningIcon from '@material-ui/icons/Warning';
import {
    PMZCalc,
    margemBrutaCalc,
    margemBrutaPercentVariationCalc,
    margemOperacionalCalc,
    margemOperacionalPercentVariationCalc,
    outrosCustosCalc,
    precoEditadoEPrecoTabelaAtualDiffCalc,
    precoMedioDiffCalc,
    precoMedioMargemOperacionalCalc,
    precoMedioProjetadoCalc,
    tabelaAtualPercentage,
} from '../../../../calculos';
import {
    priceStatusIndicator,
    tableLengthMenu
} from '../../../../utils';
import { useEditBaseTableStore } from '../../EditBaseTable.store';
import { EditBaseTableItemDataType, EditBaseTableListResponse } from '../../types';
import styles from './EditTable.module.scss';

const {
 Column, HeaderCell, Cell, Pagination,
} = Table;

type EditTableProps = {
    data: EditBaseTableListResponse,
    onChangeCheckbox?: (product: EditBaseTableItemDataType, checked: boolean) => void,
    onBlurCustoFabricacao?: (event: React.FocusEvent<HTMLInputElement>, product: EditBaseTableItemDataType) => void,
    onChangePage?: (value: number) => void,
    onChangeLength?: (value: number) => void,
    onBlurPrecoMedio?: (event: React.FocusEvent<HTMLInputElement>, product: EditBaseTableItemDataType) => void,
    onBlurNovoPrecoTabela?: (event: React.FocusEvent<HTMLInputElement>, product: EditBaseTableItemDataType) => void,
}

export const EditTable = ({
    data,
    onChangeCheckbox,
    onBlurCustoFabricacao,
    onChangePage,
    onChangeLength,
    onBlurPrecoMedio,
    onBlurNovoPrecoTabela,
}: EditTableProps) => {
    const {
        setOpenModalEditOtherCosts,
        setEditBaseTableState,
        productItemSelectedList,
        setOpenModalPriceAnalysis,
    } = useEditBaseTableStore();

    const handleEditOtherCosts = (product: EditBaseTableItemDataType) => {
        setOpenModalEditOtherCosts(true, product);
    };

    const handleOnClickDetails = (rowData: EditBaseTableItemDataType) => {
        setOpenModalPriceAnalysis(true, rowData)
    }

    return (
        <div className={styles['table-edit-base-container']}>
            <LoadingSpinerArea area="table-edit-base" />
            <Table
                data={data?.content}
                autoHeight
                id="table-edit-base"
                className={styles['table-edit-base']}
                rowClassName={styles['table-edit-base-row']}
                rowHeight={64}
                headerHeight={46}
                // sortColumn={tableState.sortColumn}
                // sortType={tableState.sortType}
                // onSortColumn={tableHandlers.handleSortColumns}
            >
                <Column align="center" width={60}>
                    <HeaderCell style={{ padding: '2px' }}>
                        {/* <Checkbox
                            indeterminate={indeterminate}
                            checked={selectedScenario.length === tableData.totalElements}
                            onChange={tableHandlers.handleChangeAllCheckbox}
                        /> */}
                    </HeaderCell>
                    <Cell>
                        {(rowData) => (
                            <Checkbox
                                checked={
                                    productItemSelectedList.some((item) =>
                                        item.produto.codigoInternoProduto === rowData?.produto.codigoInternoProduto)
                                }
                                value={rowData?.produto.codigoInternoProduto}
                                onChange={(v, checked) => onChangeCheckbox(rowData, checked)}
                            />
                        )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={2}>
                    <HeaderCell>
                        Produto
                    </HeaderCell>
                    <Cell dataKey="produto" height={60}>
                        {(rowData) => (
                            <Whisper
                                delayShow={0.2}
                                trigger="hover"
                                placement="bottom"
                                speaker={<Tooltip>{rowData.produto.descricaoProduto}</Tooltip>}
                            >
                                <div>
                                    <div className={styles.flex}>
                                        <p className={styles['main-bold-text']}>{rowData.produto.codigoInternoProduto}</p>
                                        <span className={styles['leader-text']}>{rowData.produto.tierProduto}</span>
                                    </div>
                                    <p className={styles['subtext-bold']}>
                                        {rowData.produto.descricaoProduto}
                                    </p>
                                </div>
                            </Whisper>
                        )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        Família
                    </HeaderCell>
                    <Cell dataKey="linha" height={60}>
                        {(rowData) => (
                            <div className={styles.flex}>
                                <p className={styles['regular-text-family']}>{rowData.produto.categoriaNivel1 || '-'}</p>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>UF</HeaderCell>
                    <Cell dataKey="uf">
                        {(rowData) => (
                            <div className={styles.flex}>
                                <p className={styles['regular-text']}>
                                    {/* {rowData.produto.tabela.uf} */}
                                    Nacional
                                </p>
                                {/* <LinkButton fontSize="10px">Ver UFs</LinkButton> */}
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell className={styles.line}>
                        <span className={styles['flex-column']}>
                            <p>Custo</p>
                            <p>fabricação</p>
                        </span>
                    </HeaderCell>
                    <Cell
                        dataKey="custoFabricacao"
                        className={styles.line}
                    >
                        {(rowData) => (
                            <div className={styles['margem-objetiva-cell-wrapper']}>
                                <InputGroup className={styles['input-group']}>
                                    <InputGroup.Addon>R$</InputGroup.Addon>
                                    <InputCurrency
                                        className={styles['currency-input']}
                                        value={rowData.parametros.custoFabricacao}
                                        decimalSeparator=","
                                        thousandSeparator='.'
                                        onBlur={(e) => onBlurCustoFabricacao(e, rowData)}
                                    />
                                </InputGroup>
                                {/* <div className={styles['neutro-indicator']}>
                                    {rowData.custoFabricacao}
                                    %
                                </div> */}
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Outros</p>
                            <p>custos</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="outros">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        value={outrosCustosCalc(rowData)}
                                        displayType={'text'}
                                        prefix={'R$ '}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                    {/* {utils.formatNumberToCurrency(rowData.outputs.new_price_scenario.price, 2)} */}
                                </p>
                                <div className={styles['outros-indicator-flex']}>
                                    <LinkButton
                                        fontSize="10px"
                                        onClick={() => handleEditOtherCosts(rowData)}
                                    >
                                        Editar
                                    </LinkButton>
                                </div>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>PMZ</HeaderCell>
                    <Cell dataKey="pmz">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        value={PMZCalc(rowData)}
                                        displayType={'text'}
                                        prefix={'R$ '}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                                {/* <div className={[styles['neutro-indicator'], styles['pmz-indicator']].join(' ')}>
                                    {rowData.pmzInd}
                                    %
                                </div> */}
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell className={styles.line}>
                        <span className={styles['flex-column']}>
                            <p>Tabela</p>
                            <p>atual</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="tabelaAtual" className={styles.line}>
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        value={rowData.tabelaAtual.valorTabelaAtual}
                                        displayType={'text'}
                                        prefix={'R$ '}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                    {/* {utils.formatLocaleNumber(rowData.outputs.new_price_scenario?.demand_forecast, 0)} */}
                                </p>
                                <Whisper
                                    delayShow={0.2}
                                    trigger="hover"
                                    placement="bottom"
                                    speaker={<Tooltip>Margem de lucro operacional</Tooltip>}
                                >
                                    <span className={styles['currency-indicator']}>
                                        $
                                        {' '}
                                        {tabelaAtualPercentage(rowData)}
                                        %
                                        {/* {utils.formatLocaleNumber(rowData.outputs.base_price_scenario?.demand_forecast, 0)} */}
                                    </span>
                                </Whisper>
                            </div>
                )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Preço</p>
                            <p>médio atual</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="precoMedioAtual" className={styles['input-cell']}>
                        {(rowData) => (
                            <div className={styles['margem-objetiva-cell-wrapper']}>
                                <InputGroup className={styles['input-group']}>
                                    <InputGroup.Addon>R$</InputGroup.Addon>
                                    <InputCurrency
                                        className={styles['currency-input']}
                                        decimalSeparator=","
                                        thousandSeparator='.'
                                        value={rowData.parametros.precoMedio.valorAtual}
                                        onBlur={(e) => onBlurPrecoMedio(e, rowData)}
                                    />
                                </InputGroup>
                                <div className={styles.flex}>
                                    <Whisper
                                        delayShow={0.2}
                                        trigger="hover"
                                        placement="bottom"
                                        speaker={<Tooltip>Margem de lucro operacional</Tooltip>}
                                    >
                                        <span className={styles['currency-indicator']}>
                                            $
                                            {' '}
                                            {/* rowData.parametros.precoMedio.margemOperacional*/}
                                            {precoMedioMargemOperacionalCalc(rowData)}
                                            %
                                            {/* {utils.formatLocaleNumber(rowData.outputs.base_price_scenario?.demand_forecast, 0)} */}
                                        </span>
                                    </Whisper>
                                    <Whisper
                                        delayShow={0.2}
                                        trigger="hover"
                                        placement="bottom"
                                        speaker={
                                            <Tooltip>
                                                {rowData.parametros.precoMedio.valorAtual === 0 || rowData.parametros.precoMedio.valorAtual === null
                                                    ? 'Desde a última aplicação do preço na tabela base não foi adicionado um novo Preço médio atual'
                                                    : 'Diferença entre tabela base e preço médio'
                                                }
                                            </Tooltip>
                                        }
                                    >
                                        <span>
                                            {rowData.parametros.precoMedio.valorAtual === 0 || rowData.parametros.precoMedio.valorAtual === null
                                                ? (<WarningIcon className={styles['warning-icon']} />)
                                                : (
                                                    <Indicator
                                                        className={styles['preco-medio-indicator']}
                                                        fontSize="10px"
                                                        value={precoMedioDiffCalc(rowData)}
                                                        // value={rowData.parametros.precoMedio.ultimaDiferencaPercentual}
                                                    />
                                                )
                                            }

                                        </span>
                                    </Whisper>
                                </div>
                            </div>
                )}
                    </Cell>
                </Column>
                {/* <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Vendas</p>
                            <p>(un) 12 m.</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="vendas">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    {rowData.vendas} */}
                {/* {utils.formatNumberToCurrency(rowData.outputs.new_price_scenario?.profit_forecast, 0)} */}
                {/* </p>
                                <LinkButton
                                    fontSize="10px"
                                    className={styles['btn-simulate']}
                                >
                                    Simular
                                </LinkButton>
                            </div>
                            )}
                    </Cell> */}
                {/* </Column> */}
                <Column sortable flexGrow={1}>
                    <HeaderCell className={styles.line}>
                        <span className={styles['flex-column']}>
                            <p>Novo preço</p>
                            <p>de tabela</p>
                        </span>
                    </HeaderCell>
                    <Cell
                        dataKey="novoPrecoTabela"
                        className={[styles['input-cell'], styles.line].join(' ')}
                    >
                        {(rowData) => (
                            <div>
                                <InputGroup
                                    className={styles[
                                        rowData.parametros.novoPrecoTabela.statusPreco === 'EDITADO'
                                            ? 'input-group-editado'
                                            : 'input-group'
                                    ]}
                                >
                                    <InputGroup.Addon>R$</InputGroup.Addon>
                                    <InputCurrency
                                        className={styles['currency-input']}
                                        decimalSeparator=","
                                        thousandSeparator='.'
                                        value={rowData.parametros.novoPrecoTabela.valorNovo}
                                        onBlur={(e) => onBlurNovoPrecoTabela(e, rowData)}
                                    />
                                </InputGroup>
                                <div className={styles.flex}>
                                    <Whisper
                                        delayShow={0.2}
                                        trigger="hover"
                                        placement="bottom"
                                        speaker={<Tooltip>Variação em comparação à tabela base atual</Tooltip>}
                                    >
                                        <span>
                                            <Indicator
                                                fontSize="10px"
                                                value={precoEditadoEPrecoTabelaAtualDiffCalc(rowData)}
                                            />
                                        </span>
                                    </Whisper>
                                    <div
                                        className={styles[priceStatusIndicator(rowData)]}
                                    >
                                        {rowData.parametros.novoPrecoTabela.statusPreco}
                                    </div>
                                </div>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Preço médio</p>
                            <p>projetado</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="outros">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        value={precoMedioProjetadoCalc(rowData)}
                                        displayType={'text'}
                                        prefix={'R$ '}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                    {/* {utils.formatNumberToCurrency(rowData.outputs.new_price_scenario.price, 2)} */}
                                </p>
                                <div className={styles['outros-indicator-flex']}>
                                    <LinkButton
                                        fontSize="10px"
                                        onClick={() => handleOnClickDetails(rowData)}
                                    >
                                        Detalhes
                                    </LinkButton>
                                </div>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Margem</p>
                            <p>bruta</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="margemLucroBruto">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={1}
                                        value={margemBrutaCalc(rowData)}
                                        displayType={'text'}
                                        suffix={'%'}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                                <div className={styles.flex}>
                                    <Indicator
                                        className={styles['margem-indicator']}
                                        fontSize="10px"
                                        value={margemBrutaPercentVariationCalc(rowData)}
                                    />
                                </div>
                            </div>
                            )}
                    </Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>
                        <span className={styles['flex-column']}>
                            <p>Margem </p>
                            <p>operacional</p>
                        </span>
                    </HeaderCell>
                    <Cell dataKey="margemLucroOperacional">
                        {(rowData) => (
                            <div>
                                <p className={styles['main-bold-text']}>
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={1}
                                        value={margemOperacionalCalc(rowData)}
                                        displayType={'text'}
                                        suffix={'%'}
                                        decimalSeparator={','}
                                        thousandSeparator={'.'}
                                    />
                                </p>
                                <div className={styles.flex}>
                                    <Indicator
                                        className={styles['margem-indicator']}
                                        fontSize="10px"
                                        value={margemOperacionalPercentVariationCalc(rowData)}
                                    />
                                </div>
                            </div>
                            )}
                    </Cell>
                </Column>
            </Table>
            <Pagination
                activePage={data?.page.number + 1 || null}
                displayLength={data?.page.size}
                total={data?.page.totalElements || null}
                onChangePage={onChangePage}
                onChangeLength={onChangeLength}
                lengthMenu={tableLengthMenu}
            />
        </div>

    );
};
