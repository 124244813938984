import { GerenciadorPrecos } from '../../../../@types/GerenciadorPrecos';

type Condition = {
    condition: GerenciadorPrecos.Table.ExpandedRowMenu;
    height: number;
};
export class ExpandedRowHeight {
    private static conditions: Condition[] = [
        { condition: 'HEADER', height: 24 },
        { condition: 'COMPETITORS', height: 180 },
        { condition: 'WHOLESALE', height: 63 },
        { condition: 'OFFER', height: 63 },
        { condition: 'OFFERPRICE_COMPETITORS', height: 180 },
    ];

    static getRowHeight(option: Condition['condition']) {
        const condition = ExpandedRowHeight.conditions.find((c) => c.condition === option);
        if (!condition) return 0;
        return condition.height;
    }

    static calculateExpandedRowHeight(options: Condition['condition'][]): number {
        return ExpandedRowHeight.conditions.reduce((totalHeight, condition) => {
            if (options.includes(condition.condition)) return totalHeight + condition.height;
            return totalHeight;
        }, 0);
    }
}
