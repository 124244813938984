import React from 'react';
import Layout from '../../Layout';

const { Footer, Action } = Layout;

type SetupIpaProductsSegmentationFooterProps = {
    onReset: () => void;
    isLoading: boolean;
};

export const SetupIpaProductsSegmentationFooter = ({ isLoading, onReset }: SetupIpaProductsSegmentationFooterProps) => {
    return (
        <Footer>
            <Action type="reset" theme="ghost" onClick={onReset}>
                cancelar
            </Action>
            <Action type="submit" disabled={isLoading}>
                salvar
            </Action>
        </Footer>
    );
};
