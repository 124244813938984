import { useEffect, useState } from 'react';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import { OtherPrices, PriceOption, SetupIPADadosDeConcorrencia } from '../SetupIPADadosDeConcorrenciatypes';

export const usePriceOptions = (data?: SetupIPADadosDeConcorrencia, setValue?: any) => {
    const [priceOption, setPriceOption] = useState<string>('');
    const [competitorPricePMCToggle, setCompetitorPricePMCToggle] = useState(false);
    const [otherPrices, setOtherPrices] = useState<Array<string>>([]);

    useEffect(() => {
        if (!data) return;
        if (data.competitor_data) {
            setValue('competitor_days', data.competitor_days);
            setValue('suppliers_competitor_days', data.suppliers_competitor_days);
            setValue('id', data.id ? data.id : null);
            setValue('price_option', data.competitor_data.price_option);
            setValue('other_prices', data.competitor_data.other_prices || []);
            setPriceOption(data.competitor_data.price_option);
            setCompetitorPricePMCToggle(data.competitor_data?.limit_in_pmc || false);
            setOtherPrices(data.competitor_data.other_prices || []);
        }
    }, [data, setValue]);

    const handlePriceOptionChange = (value: PriceOption, field: ControllerRenderProps<FieldValues, 'price_option'>) => {
        setPriceOption(value);
        field.onChange(value);
    };

    const handleToggleCompetitorPricePMC = (value: boolean, field: ControllerRenderProps<FieldValues, 'price_option_toggle'>) => {
        setCompetitorPricePMCToggle(value);
        field.onChange(value);
    };

    const handleOtherPricesChange = (value: OtherPrices) => {
        if (otherPrices.includes(value)) {
            setOtherPrices(otherPrices.filter((price) => price !== value));
        } else {
            setOtherPrices([...otherPrices, value]);
        }
    };

    return {
        priceOption,
        otherPrices,
        competitorPricePMCToggle,
        handlePriceOptionChange,
        handleOtherPricesChange,
        handleToggleCompetitorPricePMC,
    };
};
