import { useQuery } from '@tanstack/react-query';
import * as service from '../services';
import { useState } from 'react';
import { useManageComercialTableStore } from '../ManageComercialTable.store';

export const useConsultComercialTable = () => {
    const [ consultComercialTableID, setConsultComercialTableID ] = useState(null)

    const {
        setManageComercialTableState,
    } = useManageComercialTableStore()

    const getConsultComercialTableData = async (id) => {
        if (id) {
            const resp = await service.getConsultComercialTable(id);

            if (resp.status === 200) {
                setManageComercialTableState('comercialTablePriceLimitAndRules', resp.data)
            }
            return resp;
        }
    };

    const {
        isLoading, isError, data, error, isFetching, refetch,
    } = useQuery(
        {
            queryKey: ['consult-comercial-table', consultComercialTableID],
            queryFn: () => getConsultComercialTableData(consultComercialTableID),
            retry: false,
        },
    );

    return {
        isLoading,
        isError,
        consultComercialTableProductData: data,
        error,
        isFetching,
        setConsultComercialTableID,
    };
};
