import React, { memo } from 'react';
import { GerenciadorPrecos } from '../../../../../@types/GerenciadorPrecos';
import { ExpandedRowCompetitors } from '../ExpandedRowCompetitors';
import { ExpandedRowTable } from '../ExpandedRowTable';
import { ExpandedRowWholesale } from '../ExpandedRowWholesale';

const MemoCompetitors = memo(ExpandedRowCompetitors);
const MemoWholesale = memo(ExpandedRowWholesale);

type RowData = GerenciadorPrecos.RowData;

type ExpandedRowWrapperProps = {
    rowData?: RowData;
};

const ExpandedRowWrapper = ({ rowData }: ExpandedRowWrapperProps) => {
    return <ExpandedRowTable rowData={rowData} />;

    // const dispatch = useDispatch();

    // const options = useSelector((state: RootState) => {
    //     return state.gerenciadorPrecosReducer.expandedRowKey.options;
    // });

    // const isExpanded = useMemo(() => options.length, [options]);

    // useEffect(() => {
    //     if (!isExpanded) dispatch(RESET_GERENCIADOR_EXPANDED_ROW());
    // }, [options, isExpanded, dispatch]);

    // if (!rowData) return null;

    // return (
    //     <ExpandedRowPricesTable>
    //         {options.includes('WHOLESALE') && <MemoWholesale rowData={rowData} />}
    //         {options.includes('OFFER') && <ExpandedRowOffer rowData={rowData} />}
    //         {options.includes('COMPETITORS') && <MemoCompetitors rowData={rowData} />}
    //     </ExpandedRowPricesTable>
    // );

    // return (
    //     <div className={styles.wrapper} data-table-row-is-expanded={isExpanded}>
    //         {true &&

    //         </ExpandedRowPricesTable> }
    //         {/* {options.includes('WHOLESALE') && <MemoWholesale rowData={rowData} />} */}
    //         {/* {options.includes('COMPETITORS') && <MemoCompetitors rowData={rowData} />} */}
    //     </div>
    // );
};

export default ExpandedRowWrapper;
