import React, { ReactNode } from 'react';
import { ContentContainer, LinkButton } from '../../../../../components';
import { EditBaseTableItemDataType, EditBaseTableListResponse } from '../../pages/EditBaseTable/types';
import styles from './TableActionArea.module.scss';

type TableActionAreaProps = {
    data?: EditBaseTableListResponse,
    selectedItems?: EditBaseTableItemDataType[],
    children?: ReactNode,
    selectAll?: boolean,
    onClickSelectAll?: (all: boolean) => void,
}

export const TableActionArea = ({
    data,
    selectedItems,
    children,
    selectAll,
    onClickSelectAll,
}: TableActionAreaProps) => {

    const selected = selectedItems?.length ? 'table-action-container-checkbox-selected' : 'table-action-container'

    return (
        <ContentContainer className={styles[selected]}>
            <div className={styles.flex}>
            {!selectedItems?.length ? (
                <>
                    <span className={styles.text}>Foram encontrados</span>
                    <span className={styles['bold-text']}>{data?.page.totalElements || 0} resultados</span>
                    <span className={styles.text}>no total</span>
                    <LinkButton
                        fontSize="12px"
                        className={styles['btn-select-all']}
                        onClick={() => onClickSelectAll(true)}
                    >
                        Selecionar tudo
                    </LinkButton>
                </>
            ) : (
                <>
                    <span className={styles['bold-text']}>
                        {selectAll ? data?.page.totalElements : selectedItems?.length}
                        {selectedItems?.length === 1 ? ' produto selecionado' : ' produtos selecionados'}
                    </span>
                    <span className={styles.text}>no total</span>
                    <LinkButton
                        fontSize="12px"
                        className={styles['btn-select-all']}
                        onClick={() => onClickSelectAll(false)}
                    >
                        Limpar seleção
                    </LinkButton>
                </>
            )}
            </div>
            <div className={styles.flex}>
                {children}
            </div>
        </ContentContainer>
    );
};
