import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { Alert } from 'rsuite';
import { useManageComercialTableStore } from '../ManageComercialTable.store';
import * as service from '../services';

export const useComercialTableProductList = () => {
    const [ comercialTableParams, setComercialTableParams ] = useState({ page: 0, size: 20, idTabelaComercial: null })
    const [ comercialTableData, setComercialTableData ] = useState([])
    const {
        comercialTableItem,
        setOpenModalProductCostComercialTable,
    } = useManageComercialTableStore();

    const getComercialTableProductList = async (params) => {
        if (params.idTabelaComercial) {
            const resp = await service.getComercialTableProducts(params);
            return resp;
        }
    };

    const {
        isLoading, isError, data, error, isFetching, refetch,
    } = useQuery(
        {
            queryKey: ['comercial-table-product-list', comercialTableParams],
            queryFn: () => getComercialTableProductList(comercialTableParams),
            retry: false,
            onSuccess: (data) => {
                setComercialTableData(data)
            },
        },
    );

    const saveComercialCostMutation = useMutation({
        mutationFn: service.saveComercialTableProductCost,
        onSuccess: () => {
            refetch();
            setOpenModalProductCostComercialTable(false, null);
        },
    });

    const saveComercialPriceMutation = useMutation({
        mutationFn: service.saveComercialTableNewPrice,
    });

    const handleBlurNovoPrecoTabela = (event: React.FocusEvent<HTMLInputElement>, productData) => {
        const value = Number(event.target.value.replace(',', '.'))
        const saveBody = {idRascunhoProdutoComercial: productData.produtoComercial.id, novoPrecoTabela: value}
        const newData = comercialTableData?.data.content.map(
            (item) => item.id === productData?.id
                ? {
                    ...item, parametros: {
                        ...item.parametros,
                        novoPrecoTabela: {
                            ...item.parametros.novoPrecoTabela,
                            valorNovo: value
                        }
                    }}
                : item
        )

        if (value === productData.parametros.novoPrecoTabela.valorNovo) {
            return;
        }

        setComercialTableData({...comercialTableData, data: {...comercialTableData.data, content: newData}})
        saveComercialPriceMutation.mutateAsync(saveBody);
    }

    const handleSaveComercialTablePorductCost = useCallback(
        async (formData) => {
            try {
                await saveComercialCostMutation.mutateAsync(formData);
            } catch (err) {
                throw Alert.error(err.message);
            }
        },
        [saveComercialCostMutation.mutateAsync],
    );

    return {
        isLoading,
        isError,
        comercialTableProductData: comercialTableData,
        error,
        isFetching,
        comercialTableParams,
        setComercialTableData,
        setComercialTableParams,
        handleSaveComercialTablePorductCost,
        handleBlurNovoPrecoTabela
    };
};
