import { useQuery } from '@tanstack/react-query';
import { useCreateComercialTableStore } from '../CreateComercialTable.store';
import * as service from '../services';

export const useTabelaBase = () => {
    const { createComercialTableBody, setCreateComercialTableState } = useCreateComercialTableStore();

    const getTabelaBaseData = async () => {
        const res = await service.getTabelaBase();

        if (res.status === 200) {
            const list = res.data.content.map((item) => ({ label: item.uf, value: item.id }));
            return list;
        }

        return [];
    };

    const {
        data,
        isLoading,
        isError,
        isSuccess,
        refetch,
    } = useQuery({
        queryKey: ['tabela-base'],
        queryFn: getTabelaBaseData,
        onSuccess(data) {
            const id = {...createComercialTableBody, idTabelaBase: data[0].value}
            setCreateComercialTableState('createComercialTableBody', id)
        },
        retry: false,
    });

    return {
        tabelaBaseData: data,
        isError,
        isSuccess,
        refetchTabelaBase: refetch,
    };
};
