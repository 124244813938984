import React from 'react'
import { ContextMenuContent, ContextMenuItem, ContextMenuSeparator } from '../../../../../../../components';

type NewPriceTableInputContextMenuProps = {
    onClickPriceLimit?: () => void,
}

export const NewPriceTableInputContextMenu = ({ onClickPriceLimit }: NewPriceTableInputContextMenuProps) => {
    return (
        <ContextMenuContent>
            {/* <ContextMenuItem
                // onClick={() =>
                //     handleOpenPriceCompositionModal(
                //         contextMenuDatapoint.productId,
                //         contextMenuDatapoint.storeId,
                //     )
                // }
            >
                Todas as tabelas do produto
            </ContextMenuItem>
            <ContextMenuItem
                // onClick={() =>
                //     handleOpenCompositionRuleModal(
                //         contextMenuDatapoint.ruleId,
                //         contextMenuDatapoint.productsToBePricedId,
                //     )
                // }
            >
                Desfazer alterações de preço
            </ContextMenuItem>
            <ContextMenuSeparator /> */}
            <ContextMenuItem
                onClick={onClickPriceLimit}
            >
                Limite de preço
            </ContextMenuItem>
            {/* <ContextMenuSeparator />
            <ContextMenuItem
                // onClick={() =>
                //     handleOpenPriceCompositionModal(
                //         contextMenuDatapoint.productId,
                //         contextMenuDatapoint.storeId,
                //     )
                // }
            >
                Editar custos do produto
            </ContextMenuItem>
            <ContextMenuItem
                // onClick={() =>
                //     handleOpenCompositionRuleModal(
                //         contextMenuDatapoint.ruleId,
                //         contextMenuDatapoint.productsToBePricedId,
                //     )
                // }
            >
                Ativar oferta do produto
            </ContextMenuItem> */}
        </ContextMenuContent>
    )
}
