import { useQuery } from '@tanstack/react-query';
import * as service from '../services';
// import { useEditBaseTableStore } from '../EditBaseTable.store';
import { useState } from 'react';
import { useManageComercialTableStore } from '../ManageComercialTable.store';
// import { TableRequestType } from '../types';

export const useComercialTableList = () => {
    const [ editBaseTableList, setEditBaseTableList ] = useState({ page: 0, size: 10 })
    const [ comercialTableMutateData, setComercialTableMutateData ] = useState({})
    const [ comercialTableHeaderData, setComercialTableHeaderData ] = useState([])
    const {
        comercialTableItem,
        setManageComercialTableState,
    } = useManageComercialTableStore()

    const getComercialTableList = async (data) => {
        const resp = await service.getComercialTableList(data);
        return resp;
    };

    const {
        isLoading, isError, data, error, isFetching, refetch,
    } = useQuery(
        {
            queryKey: ['edit-base-table-list', editBaseTableList],
            queryFn: () => getComercialTableList(editBaseTableList),
            retry: false,
            onSuccess: (data) => {
                const firstItem = data.data.content[0]
                const hasItem = comercialTableItem?.id ? comercialTableItem : firstItem

                const headerList = data.data.content.map((item) => (
                    {
                        label: item.nome,
                        value: item.id
                    }
                ))

                setComercialTableHeaderData(headerList)
                setComercialTableMutateData(data)
                setManageComercialTableState('comercialTableItem', hasItem)
            },
        },
    );

    const handleChangeComercialTableListPage = (value: number) => {
        setEditBaseTableList({...editBaseTableList, page: value - 1})
    }

    const handleChangeComercialTableListLength = (value: number) => {
        setEditBaseTableList({page: 0, size: value})
    }

    return {
        isLoading,
        isError,
        comercialTableData: data,
        error,
        isFetching,
        comercialTableMutateData,
        comercialTableHeaderData,
        setComercialTableMutateData,
        handleGetEditTableList: refetch,
        setEditBaseTableList,
        handleChangeComercialTableListPage,
        handleChangeComercialTableListLength
    };
};
