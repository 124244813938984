import { Control, FieldValues } from 'react-hook-form';

export enum PriceOption {
    REGULAR = 'REGULAR',
    REGULAR_AND_OTHERS = 'REGULAR_AND_OTHERS',
}

export enum OtherPrices {
    REGULAR_PRICE = 'REGULAR_PRICE',
    PROMOTIONAL_PRICE = 'PROMOTIONAL_PRICE',
    LOWER_PRICE = 'LOWER_PRICE',
    PROMOTIONAL_PRICE_FROM_BY = 'PROMOTIONAL_PRICE_FROM_BY',
    PROMOTIONAL_PRICE_OFFER = 'PROMOTIONAL_PRICE_OFFER',
    PROMOTIONAL_PRICE_FEATURED_PRODUCT = 'PROMOTIONAL_PRICE_FEATURED_PRODUCT',
    PROMOTIONAL_PRICE_OTHER_OFFERS = 'PROMOTIONAL_PRICE_OTHER_OFFERS',
    DISCOUNT_CLUB = 'DISCOUNT_CLUB',
}

export type CostField =
    | 'LAST_UNIT_NET_COST'
    | 'MEAN_UNIT_NET_COST'
    | 'MAX_UNIT_NET_COST'
    | 'GLOBAL_MEAN_UNIT_NET_COST'
    | 'GLOBAL_MAX_UNIT_NET_COST'
    | 'MAX_FAMILY_UNIT_NET_COST';

export type CompetitorData = {
    price_option: PriceOption;
    limit_in_pmc?: boolean;
    other_prices?: Array<OtherPrices>;
};

export interface SetupIPADadosDeConcorrencia {
    id: string | null;
    client_id: number | null;
    client: string | null;
    cost_field: CostField;
    created_by: string | null;
    update_by: string | null;
    created_date: string | null;
    competitor_days: number;
    suppliers_competitor_days: number;
    competitor_data: CompetitorData;
}

export type CompetitorDaysData = Pick<SetupIPADadosDeConcorrencia, 'competitor_days' | 'suppliers_competitor_days' | 'competitor_data'>;

export interface ControlReactHookForm {
    control: Control<FieldValues>;
}
