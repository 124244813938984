import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'rsuite';
import { LinkButton, LoadingSpinerArea } from '../../../../../components';
import {
    MainContainer,
    TableActionArea
} from '../../components';
import { SelectUpdatedHeader } from '../../components/SelectUpdatedHeader';
import { ModalCustosProduto, ModalPriceAnalysis } from '../EditBaseTable/components';
import styles from './ManageComercialTable.module.scss';
import { useManageComercialTableStore } from './ManageComercialTable.store';
import {
    ManageComercialTableTable,
    ModalActivateProductPromo,
    ModalDescriptionPriceAndDiscount,
    ModalPriceLimit,
} from './components';
import { useComercialPriceApply } from './hooks/useComercialPriceApply';
import { useComercialTableList } from './hooks/useComercialTableList';
import { useComercialTableProductList } from './hooks/useComercialTableProductList';
import { useConsultComercialTable } from './hooks/useConsultComercialTable';

const MemoModalPriceLimit = React.memo(ModalPriceLimit);

export const ManageComercialTable = () => {
    const history = useHistory();

    const {
        priceAnalysisComercialTableData,
        openModalPriceAnalysisComercial,
        openModalActivateProductOffer,
        openModalProductCostComercialTable,
        openModalPriceLimit,
        productItemData,
        custosProdutoBody,
        comercialTableItem,
        comercialProductItemSelectedList,
        selectAllTable,
        setManageComercialTableState,
        setOpenModalProductCostComercialTable,
        setOpenModalPriceAnalysisComercial,
    } = useManageComercialTableStore()

    const {
        comercialTableHeaderData,
        comercialTableMutateData,
    } = useComercialTableList();

    const { setConsultComercialTableID } = useConsultComercialTable()

    const {
        comercialTableProductData,
        comercialTableParams,
        setComercialTableParams,
        handleSaveComercialTablePorductCost,
        handleBlurNovoPrecoTabela,
    } = useComercialTableProductList();

    const { handleConfirmPriceApply, handleConfirmPriceApplyAll } = useComercialPriceApply()

    const handleChangeCustosProduto = (objType: string, name: string, value: number) => {
        const objData = {
            ...custosProdutoBody,
            [objType]: {
                ...custosProdutoBody[objType],
                [name]: value
            }
        }

        const custoFabricacao = {
            ...custosProdutoBody,
            [name]: value,
        }

        const isCustoFabricacao = objType === 'custoFabricacao' ? custoFabricacao : objData

        setManageComercialTableState('custosProdutoBody', isCustoFabricacao)
    }

    const handleSelectTableType = (id: number) => {
        const newItem = comercialTableMutateData.data.content.filter((item) => item.id === id)
        setComercialTableParams({
            ...comercialTableParams,
            idTabelaComercial: id,
        })
        setConsultComercialTableID(id)
        setManageComercialTableState('comercialTableItem', newItem[0])
    }

    const handleChangeCheckboxComercialProduct = (product, checked) => {
        const addProduct = checked
            ? [...comercialProductItemSelectedList, product]
            : comercialProductItemSelectedList.filter((item) => item.id !== product.id);

        const productIds = addProduct.map((item) => item.id)

        setManageComercialTableState('comercialProductItemSelectedList', addProduct)
        setManageComercialTableState('comercialPriceApplyList', productIds)
        setManageComercialTableState('selectAllTable', false)
    }

    const handleClickSelectAllProducts = (all: boolean) => {
        setManageComercialTableState('comercialProductItemSelectedList', all ? comercialTableProductData?.data?.content : [])
        setManageComercialTableState('selectAllTable', all)
    }

    const handlePriceApply = (all: boolean) => {
        if (all) {
            handleConfirmPriceApplyAll();
        } else {
            handleConfirmPriceApply();
        }
    }

    useEffect(() => {
        if (typeof comercialTableItem?.id === 'number') {
            setComercialTableParams({
                ...comercialTableParams,
                idTabelaComercial: comercialTableItem?.id,
            })
            setConsultComercialTableID(comercialTableItem?.id)
        }
    }, [comercialTableItem?.id])

    return (
        <MainContainer>
            <LoadingSpinerArea area='table-edit-base' />
            <div className={styles['header-wrapper']}>
                <div>
                    <SelectUpdatedHeader
                        isPending={comercialTableItem?.revisaoPendente}
                        date={comercialTableItem?.aplicadoEm}
                        value={comercialTableItem?.id}
                        data={comercialTableHeaderData}
                        onChange={handleSelectTableType}
                    />
                    <div className={styles['subtitle-wrapper']}>
                        <p className={styles.subtitle}>As alterações são salvas automaticamente</p>
                        <LinkButton
                            fontSize="12px"
                            onClick={() => history.push('/ipa/criar-tabela-comercial')}
                        >
                            Editar regras da tabela
                        </LinkButton>
                    </div>
                </div>
                <Button
                    className={styles['btn-consult']}
                    onClick={() => history.push('/ipa/consultar-tabela-comercial')}
                >
                    Consultar a tabela atual
                </Button>
            </div>
            {/* <BigNumbersArea />
            <FiltersArea /> */}
            <TableActionArea
                data={comercialTableProductData?.data}
                selectedItems={comercialProductItemSelectedList}
                selectAll={selectAllTable}
                onClickSelectAll={handleClickSelectAllProducts}
            >
                <Button
                    className={styles['btn-apply']}
                    appearance="primary"
                    disabled={!comercialProductItemSelectedList.length}
                    onClick={() => handlePriceApply(selectAllTable)}
                >
                    Aplicar preço
                </Button>
            </TableActionArea>
            <ManageComercialTableTable
                data={comercialTableProductData?.data?.content}
                onBlurNovoPrecoTabela={handleBlurNovoPrecoTabela}
                onChangeCheckbox={handleChangeCheckboxComercialProduct}
            />
            <ModalActivateProductPromo
                show={openModalActivateProductOffer}
                onCancel={() => setManageComercialTableState('openModalActivateProductOffer', false)}
            />
            <ModalDescriptionPriceAndDiscount />
            <MemoModalPriceLimit show={openModalPriceLimit} />
            <ModalPriceAnalysis
                className="comercial"
                show={openModalPriceAnalysisComercial}
                tableHeight={273}
                title='Análise preço médio vs preço de tabela'
                data={priceAnalysisComercialTableData}
                onCancel={() => setOpenModalPriceAnalysisComercial(false, null)}
            />
            <ModalCustosProduto
                show={openModalProductCostComercialTable}
                hasCustoFabricacao
                title="Custos do produto"
                subtitle="Defina os custos específicos dos produtos selecionados"
                confirmButton="Salvar"
                cancelButton="Fechar"
                loadingArea='table-edit-base'
                disabledImpostos
                disabledCustoFabricacao
                disabledCustosVenda
                disabledOutrasDespesas
                data={productItemData}
                onCancel={() => setOpenModalProductCostComercialTable(false, null)}
                onConfirm={() => handleSaveComercialTablePorductCost(custosProdutoBody)}
                onChangeCustosProduto={handleChangeCustosProduto}
            />
        </MainContainer>
    );
};
