import { useQuery } from '@tanstack/react-query';
import * as service from '../services';
import { useCurrentBaseTableStore } from '../CurrentBaseTable.store';
import { useState } from 'react';
import { TableRequestType } from '../types';

export const useTabelaBaseAtualList = () => {
    const { setEditBaseTableState } = useCurrentBaseTableStore();
    const [ tabelaBaseAtualList, setTabelaBaseAtualList ] = useState({ page: 0, size: 20 })
    const [ tableData, setTableData ] = useState({})

    const getTabelaBaseData = async (data: TableRequestType) => {
        const resp = await service.getTabelaBaseList(data);
        return resp;
    };

    const {
        isLoading, isError, data, error, isFetching, refetch,
    } = useQuery(
        {
            queryKey: ['tabela-base-atual-list', tabelaBaseAtualList],
            queryFn: () => getTabelaBaseData(tabelaBaseAtualList),
            retry: false,
            onSuccess: (data) => {
                setTableData(data)
            },
        },
    );

    const handleChangeTabelaBaseAtualPage = (value: number) => {
        setTabelaBaseAtualList({...tabelaBaseAtualList, page: value - 1})
    }

    const handleChangeTabelaBaseAtualLength = (value: number) => {
        setTabelaBaseAtualList({page: 0, size: value})
    }

    return {
        isLoading,
        isError,
        tabelaBaseAtualData: data,
        error,
        isFetching,
        tableData,
        setTableData,
        handleGetTabelaBaseAtual: refetch,
        setTabelaBaseAtualList,
        handleChangeTabelaBaseAtualPage,
        handleChangeTabelaBaseAtualLength
    };
};
