import classNames from 'classnames';
import Decimal from 'decimal.js';
import React, { ComponentProps, forwardRef, memo, useCallback, useMemo } from 'react';
import CurrencyFormat from 'react-currency-format';
import { MdOutlineClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Divider } from 'rsuite';
import { GerenciadorPrecos } from '../../../../../@types/GerenciadorPrecos';
import { RootState } from '../../../../../@types/RootState';
import { BaseCell, InputAddon, InputCurrency, InputGroup } from '../../../../../components';
import ExceptionSaveDatapoint from '../../../../../data/ExceptionSaveDatapoint';
import { IPAMaths } from '../../../../../lib';
import { SET_GERENCIADOR_DATAPOINT_BY_INDEX, selectorDatapoints } from '../../../../../reducers/gerenciadorPrecos/datapoints';
import { REMOVE_GERENCIADOR_EXPANDED_ROW_OPTION } from '../../../../../reducers/gerenciadorPrecos/expandedRowKey';
import { CompetitivenessPriceIndicator, MoneyPercentageIndicator, VariationBoxIndicator } from '../../../Negociacoes/pages/NegociacaoFornecedor/components';
import { SaveEditedWholesaleDatapoint, saveEditedWholesaleDatapoint } from '../../services';
import { WholesaleQtdeCell } from '../TableCells';
import { ExpandWholesaleCell } from '../TableCells/ExpandWholesaleCell';
import styles from './ExpandedRowWholesale.module.scss';
import * as calc from './utils';

type RowData = GerenciadorPrecos.RowData;

export type ExpandedRowWholesaleProps = ComponentProps<'tr'> & {
    rowData: RowData;
};

const ExpandedRowWholesale = forwardRef<HTMLTableRowElement, ExpandedRowWholesaleProps>(({ rowData, className, children, ...props }, ref) => {
    const { productsToBePricedId, competitorsPrice, wholesale } = rowData;

    const MemoQtdeCell = memo(WholesaleQtdeCell);


    const retailPrice = wholesale?.retailPrice || 0;
    const originalMargin = wholesale?.originalMargin || 0;
    const price = wholesale?.price || 0;
    const discountPercentage = wholesale?.discountPercentage || 0;
    const newCompetitivenessPrice = wholesale?.newCompetitivenessPrice || 0;
    const newMargin = wholesale?.newMargin || 0;

    const dispatch = useDispatch();

    const datapoints = useSelector(selectorDatapoints);

    const competitivenessValue = useMemo(() => IPAMaths.novaCompetitividade(retailPrice, competitorsPrice), [competitorsPrice, retailPrice]);
    const index = useMemo(() => datapoints.findIndex((item) => item.productsToBePricedId === productsToBePricedId), [datapoints, productsToBePricedId]);

    const isDisabledEdit = useSelector((state: RootState) => {
        return !state.reducerIPA.wholesale.allowPriceEdit;
    });

    const handleClose = useCallback(() => {
        dispatch(REMOVE_GERENCIADOR_EXPANDED_ROW_OPTION('WHOLESALE'));
    }, [dispatch]);


    const handleUpdateCampoWholesale = useCallback(
        (name: string, value: number, rowData: RowData, index: number) => {
            const { wholesale, price: basePrice } = rowData;

            if (!wholesale) return;

            let updatedWholesale = { ...wholesale };

            if (value <= 0) {
                updatedWholesale = {
                    ...updatedWholesale,
                    price: value,
                    newCompetitivenessPrice: 0,
                    newMargin: 0,
                    discountPercentage: 100
                };

                dispatch(
                    SET_GERENCIADOR_DATAPOINT_BY_INDEX({
                        index,
                        rowData: { ...rowData, wholesale: updatedWholesale },
                    })
                );
                return;
            }

            switch (name) {
                case 'price': {
                    const newValues = calc.handleCalcWholesalePrice(value, rowData);
                    const discountPercentage = basePrice
                        ? IPAMaths.calcDiscountPercentage(basePrice, value)
                        : 0;

                    updatedWholesale = {
                        ...updatedWholesale,
                        ...newValues,
                        discountPercentage: Math.max(0, Math.min(100, discountPercentage || 0))
                    };
                    break;
                }

                case 'CPI': {
                    const newValues = calc.handleCalcWholesaleCPI(value, rowData);
                    const discountPercentage = basePrice && newValues.price !== null
                        ? Math.max(0, Math.min(100, IPAMaths.calcDiscountPercentage(basePrice, newValues.price) as number))
                        : 0;

                    updatedWholesale = {
                        ...updatedWholesale,
                        ...newValues,
                        discountPercentage: Math.max(0, Math.min(100, discountPercentage))
                    };
                    break;
                }

                case 'margin': {
                    const newValues = calc.handleCalcWholesaleMargin(value, rowData);

                    if (basePrice && newValues.price) {
                        const discountPercentage = IPAMaths.calcDiscountPercentage(basePrice, newValues.price);
                        (newValues as any).discountPercentage = Math.max(0, Math.min(100, (discountPercentage as any)));
                    }

                    updatedWholesale = {
                        ...updatedWholesale,
                        ...newValues
                    };
                    break;
                }

                case 'discountPercentage': {
                    const limitedDiscount = Math.max(0, Math.min(100, value));

                    const newPrice = basePrice
                        ? IPAMaths.calcPrecoWithDiscount(basePrice, limitedDiscount)
                        : 0;

                    const newValues = calc.handleCalcWholesalePrice(newPrice, rowData);

                    updatedWholesale = {
                        ...updatedWholesale,
                        ...newValues,
                        discountPercentage: limitedDiscount
                    };

                    if (new Decimal(newPrice || 0).lt(0.02)) {
                        updatedWholesale.newMargin = 0;
                        updatedWholesale.newCompetitivenessPrice = 0;
                    }
                    break;
                }
            }

            if (
                rowData?.wholesale?.price !== updatedWholesale.price ||
                rowData?.wholesale.discountPercentage !== updatedWholesale.discountPercentage
            ) {
                dispatch(
                    SET_GERENCIADOR_DATAPOINT_BY_INDEX({
                        index,
                        rowData: { ...rowData, wholesale: updatedWholesale },
                    })
                );
            }
        },
        [dispatch]
    );

    const handleBlur = useCallback(
        async (rowData: RowData) => {
            try {
                const { wholesale } = rowData;

                if (!wholesale) throw new Error('Wholesale data not found');

                const model: SaveEditedWholesaleDatapoint.Data = {
                    price: wholesale.price,
                    triggerCount: wholesale.triggerCount,
                    productId: rowData.productId,
                    storeId: rowData.storeId,
                };

                const res = await saveEditedWholesaleDatapoint(model);

                return res;
            } catch {
                const error = new ExceptionSaveDatapoint({
                    productId: rowData.productId,
                    storeId: rowData.storeId,
                    storeName: rowData.storeName,
                });
                throw Alert.error(error.message);
            }
        },
        [dispatch],
    );


    return (
        <tr {...props} ref={ref} className={classNames('', className)}>
            {/* blank column to fix layout */}
            <td style={{ width: '60px' }}>
                <ExpandWholesaleCell rowData={rowData} theme="blue">
                    2
                </ExpandWholesaleCell>
            </td>
            <td style={{ minWidth: '200px' }}>
                <BaseCell>
                    <BaseCell.MainContainer>Atacado</BaseCell.MainContainer>
                </BaseCell>
            </td>
            {/* store column blank to fix layout */}
            <td style={{ width: '80px' }}></td>
            {/* pmz column blank to fix layout */}
            <td style={{ width: '78px' }}></td>
            {/* margem objetiva column blank to fix layout */}
            <td style={{ width: '78px' }}></td>
            <td style={{ width: '64px' }}>
            <MemoQtdeCell
                rowData={rowData}
                index={index}
                handleBlur={handleBlur}
            />
            </td>
            {/* pmc column blank to fix layout */}
            <td style={{ width: '124px' }}></td>
            <td style={{ width: '124px' }}>
                {retailPrice ? (
                    <BaseCell>
                        <BaseCell.MainContainer>
                            <CurrencyFormat
                                fixedDecimalScale
                                decimalScale={2}
                                value={retailPrice}
                                displayType="text"
                                prefix="R$ "
                                decimalSeparator=","
                                thousandSeparator="."
                            />
                        </BaseCell.MainContainer>
                        <BaseCell.SubContainer>
                            <MoneyPercentageIndicator value={originalMargin} tooltip="Margem do preço vigente" />
                            <Divider vertical />
                            <CompetitivenessPriceIndicator value={competitivenessValue} />
                        </BaseCell.SubContainer>
                    </BaseCell>
                ) : (
                    <>--</>
                )}
            </td>
            <td style={{ width: '127px' }}></td>
            <td style={{ width: '132px' }}>
                <BaseCell>
                    <BaseCell.MainContainer>
                        <InputGroup>
                            <InputAddon>R$</InputAddon>
                            <InputCurrency
                                value={price}
                                name="price"
                                decimalSeparator=","
                                thousandSeparator="."
                                precision="2"
                                allowEmpty
                                allowNegative={false}
                                onChangeEvent={(_e, _m, value) => {
                                    handleUpdateCampoWholesale('price', value, rowData, index);
                                }}
                                onBlur={(_e, _v, { hasChanges }) => {
                                    if (hasChanges) handleBlur(rowData);
                                }}
                                disabled={isDisabledEdit}
                            />
                        </InputGroup>
                    </BaseCell.MainContainer>
                    <BaseCell.SubContainer>
                        <VariationBoxIndicator value={IPAMaths.variation.price(price, retailPrice)} suffix="%" />
                    </BaseCell.SubContainer>
                </BaseCell>
            </td>
            <td style={{ width: '116px' }}>
                <BaseCell>
                    <BaseCell.MainContainer>
                        <InputGroup>
                            <InputCurrency
                                name="CPI"
                                decimalSeparator=","
                                thousandSeparator="."
                                precision="1"
                                allowEmpty
                                allowNegative={false}
                                value={newCompetitivenessPrice || null}
                                onChangeEvent={(_e, _m, value) => {
                                    handleUpdateCampoWholesale('CPI', value, rowData, index);
                                }}
                                onBlur={(_e, _v, { hasChanges }) => {
                                    if (hasChanges) handleBlur(rowData);
                                }}
                                disabled
                            />
                            <InputAddon>%</InputAddon>
                        </InputGroup>
                    </BaseCell.MainContainer>
                    <BaseCell.SubContainer>
                        <VariationBoxIndicator value={IPAMaths.variation.cpi(newCompetitivenessPrice, competitorsPrice)} />
                    </BaseCell.SubContainer>
                </BaseCell>
            </td>
            <td style={{ width: '116px' }}>
                <BaseCell>
                    <BaseCell.MainContainer>
                        <InputGroup>
                            <InputCurrency
                                name="margin"
                                decimalSeparator=","
                                thousandSeparator="."
                                precision="1"
                                allowEmpty
                                allowNegative
                                value={newMargin}
                                onChangeEvent={(_e, _m, value) => {
                                    handleUpdateCampoWholesale('margin', value, rowData, index);
                                }}
                                onBlur={(_e, _v, { hasChanges }) => {
                                    if (hasChanges) handleBlur(rowData);
                                }}
                                disabled={isDisabledEdit}
                            />
                            <InputAddon>%</InputAddon>
                        </InputGroup>
                    </BaseCell.MainContainer>
                    <BaseCell.SubContainer>
                        <VariationBoxIndicator value={IPAMaths.variation.margem(newMargin, originalMargin)} />
                    </BaseCell.SubContainer>
                </BaseCell>
            </td>
            <td style={{ width: '140px' }}>
                <BaseCell>
                    <BaseCell.MainContainer>
                        <InputGroup>
                            <InputCurrency
                                name="discountPercentage"
                                decimalSeparator=","
                                thousandSeparator="."
                                precision="1"
                                allowEmpty
                                allowNegative
                                value={discountPercentage}
                                onChangeEvent={(_e, _m, value) => {
                                    handleUpdateCampoWholesale(
                                        'discountPercentage',
                                        value,
                                        rowData,
                                        index,
                                    );
                                }}
                                onBlur={(_e, _v, { hasChanges }) => {
                                    if (hasChanges) handleBlur(rowData);
                                }}
                                disabled={isDisabledEdit}
                            />
                            <InputAddon className={styles.wholesaleaddon}>% off</InputAddon>
                        </InputGroup>
                    </BaseCell.MainContainer>
                    <BaseCell.SubContainer>
                        <div className={classNames(styles.box, className)}>DESCONTO</div>
                    </BaseCell.SubContainer>
                </BaseCell>
            </td>
            <td style={{ width: '26px' }}>
                <td style={{ width: '26px' }}>
                    <button className={styles.closeButton} onClick={handleClose}>
                        <MdOutlineClose size={16} />
                    </button>
                </td>
            </td>
        </tr>
    );
});

ExpandedRowWholesale.displayName = 'ExpandedRowWholesale';

export default ExpandedRowWholesale;
