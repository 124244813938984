import React from 'react';
import { Input, SelectPicker } from 'rsuite';
import { BorderedContentContainer } from '../../../../components';

import { useCreateComercialTableStore } from '../../CreateComercialTable.store';
import { useTabelaBase } from '../../hooks/useTabelaBase';
import styles from './NewPriceTableForm.module.scss';

export const NewPriceTableForm = () => {
    const { tabelaBaseData } = useTabelaBase();
    const { createComercialTableBody, setCreateComercialTableState } = useCreateComercialTableStore();

    const handleChangeName = (value: string) => {
        const name = {...createComercialTableBody, nome: value}
        setCreateComercialTableState('createComercialTableBody', name)
    }

    const handleBaseTable = (value: string) => {
        const name = {...createComercialTableBody, idTabelaBase: value}
        setCreateComercialTableState('createComercialTableBody', name)
    }

    return (
        <BorderedContentContainer
            title="Nova tabela de preços"
            borderedContentClassName={styles['bordered-container']}
        >
            <div className={styles.row}>
                <div className={styles['input-group']}>
                    <label htmlFor="nome">Nome da tabela</label>
                    <Input
                        className={styles.input}
                        placeholder="Defina um nome para a tabela"
                        onChange={handleChangeName}
                    />
                </div>
                <div className={styles['select-group']}>
                    <label htmlFor="tabela-base">Tabela base</label>
                    <SelectPicker
                        placeholder="Tabela Base Nacional"
                        disabled
                        data={tabelaBaseData}
                        value={tabelaBaseData?.length ? tabelaBaseData?.[0].value : ''}
                        onChange={handleBaseTable}
                    />
                </div>
            </div>
        </BorderedContentContainer>
    );
};
