import React from 'react';
import { Control, Controller, ControllerRenderProps, FieldValues } from 'react-hook-form';
import { Checkbox, Radio, RadioGroup, Toggle } from 'rsuite';
import { SetupIPAHeader } from '../../SetupIPA/elements';
import styles from '../SetupIPADadosDeConcorrencia.module.scss';
import { OtherPrices, PriceOption } from '../SetupIPADadosDeConcorrenciatypes';
import { CHEBOX_OPTIONS, OPTIONS } from '../utils/options';

export const PriceOptionsSection = ({
    control,
    priceOption,
    handlePriceOptionChange,
    handleOtherPricesChange,
    showOtherPrices,
    competitorPricePMCToggle,
    handleToggleCompetitorPricePMC,
}: {
    control: Control<FieldValues>;
    priceOption: string;
    competitorPricePMCToggle: boolean;
    handlePriceOptionChange: (value: PriceOption, field: ControllerRenderProps<FieldValues, 'price_option'>) => void;
    handleToggleCompetitorPricePMC: (value: boolean, field: ControllerRenderProps<FieldValues, 'price_option_toggle'>) => void;
    handleOtherPricesChange: (value: OtherPrices) => void;
    showOtherPrices: boolean;
}) => {
    const toggle = OPTIONS.price_option.toggle;

    return (
        <div className={styles['setup-ipa__price-preference-competitor']}>
            <SetupIPAHeader
                title="Dados de concorrência no IPA"
                subtitle="Configure quais os dados de concorrência que você quer receber no IPA para a precificação regular."
            />
            <Controller
                name="price_option"
                control={control}
                defaultValue={priceOption}
                render={({ field }) => (
                    <RadioGroup {...field} value={field.value} onChange={(value) => handlePriceOptionChange(value as PriceOption, field)}>
                        {OPTIONS.price_option.options.map((option) => (
                            <div key={option.key} className={styles['setup-ipa__price-preference-competitor__option-radio']}>
                                <Radio value={option.value}>
                                    <div className={styles['text-container']}>
                                        <h4>{option.title}</h4>
                                        <span>{option.subtitle}</span>
                                    </div>
                                </Radio>
                            </div>
                        ))}
                    </RadioGroup>
                )}
            />

            {showOtherPrices && (
                <div className={styles['setup-ipa__price-preference-competitor__option-checkbox']}>
                    <h4>Outros tipos de preço</h4>
                    <p>Defina em detalhes quais preços da concorrência receber no IPA.</p>
                    <Controller
                        name="other_prices"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <div className={styles['checkbox-container']}>
                                {CHEBOX_OPTIONS.other_prices.options.map((option, index) => (
                                    <div key={option.key} className={styles['setup-ipa__price-preference-competitor__option-checkbox-item']}>
                                        <Checkbox
                                            value={option.value as OtherPrices}
                                            checked={index === 0 || field.value.includes(option.value)}
                                            disabled={index === 0}
                                            onChange={() => {
                                                if (index !== 0) {
                                                    handleOtherPricesChange(option.value as OtherPrices);
                                                    const currentValue = field.value || [];
                                                    const isChecked = currentValue.includes(option.value);

                                                    if (!isChecked) {
                                                        field.onChange([...currentValue, option.value]);
                                                    } else {
                                                        const updatedValues = currentValue.filter((v: string) => v !== option.value);
                                                        field.onChange(updatedValues);
                                                    }
                                                }
                                            }}
                                        >
                                            <div className={styles['text-container']}>
                                                <h4>{option.title}</h4>
                                                <span>{option.subtitle}</span>
                                            </div>
                                        </Checkbox>
                                    </div>
                                ))}
                            </div>
                        )}
                    />
                </div>
            )}

            {toggle.value && (
                <Controller
                    name="price_option_toggle"
                    control={control}
                    defaultValue={competitorPricePMCToggle}
                    render={({ field }) => (
                        <div className={styles['setup-ipa__price-preference-competitor__toggle']}>
                            <div className={styles['text-container']}>
                                <h4>{toggle.title}</h4>
                                <span>{toggle.subtitle}</span>
                            </div>
                            <span className={styles['toggle-wrapper']}>
                                <Toggle
                                    size="sm"
                                    defaultChecked
                                    checked={field.value || competitorPricePMCToggle}
                                    onChange={(checked) => handleToggleCompetitorPricePMC(checked, field)}
                                />
                                <span>{toggle.label}</span>
                            </span>
                        </div>
                    )}
                />
            )}
        </div>
    );
};
