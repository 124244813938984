import React from 'react';
import { Modal } from 'rsuite';
import { ModalProps } from 'rsuite/lib/Modal';
import { ButtonPrimary } from '../../ButtonPrimary';
import { FilterMenutextArea } from '../FilterMenutextArea/FilterMenutextArea';
import styles from './FilterPasteModal.module.scss';

const { Body, Footer } = Modal;

type FilterPasteModalProps = ModalProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    query: string;
    onConfirm: (query: string) => void;
    onSearch?: (query: string, e: React.ChangeEvent<HTMLInputElement>) => void;
    modalText?: string;
};

export const FilterPasteModal = ({
    show,
    query,
    modalText = 'Escreva ou cole uma lista de códigos de produto separados por espaço ou pelos caracteres , ; |  ',
    onHide,
    onConfirm,
    onSearch,
}: FilterPasteModalProps) => {
    const handleChangeValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onSearch?.(e.target.value, e as never);
    };

    return (
        <Modal className={styles.modal} show={show} onHide={onHide}>
            <Body>
                <h2 className="font-size-100-regular">Escreva ou cole uma lista de códigos de produto</h2>
                <FilterMenutextArea value={query} rows={5} placeholder={modalText} onChange={handleChangeValue} autoFocus />
            </Body>
            <Footer>
                <div className={styles.footer}>
                    <ButtonPrimary type="button" size="small" theme="ghost" onClick={onHide}>
                        Cancelar
                    </ButtonPrimary>
                    <ButtonPrimary type="button" size="small" onClick={() => onConfirm(query)}>
                        Aplicar
                    </ButtonPrimary>
                </div>
            </Footer>
        </Modal>
    );
};
