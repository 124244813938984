import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'rsuite';
import { LinkButton } from '../../../../../components';
import {
    MainContainer,
    ResultsFoundActionArea
} from '../../components';
import { SelectUpdatedHeader } from '../../components/SelectUpdatedHeader';
import { useManageComercialTableStore } from '../ManageComercialTable/ManageComercialTable.store';
import { useComercialTableList } from '../ManageComercialTable/hooks/useComercialTableList';
import styles from './ConsultComercialTable.module.scss';
import { RetailConsultCurrentTable } from './components/RetailConsultCurrentTable';
import { useConsultComercialTableProductList } from './hooks/useConsultComercialTableProductList';

export const ConsultComercialTable = () => {
    const history = useHistory()
    const {
        comercialTableItem,
    } = useManageComercialTableStore()

    const {
        comercialTableHeaderData,
    } = useComercialTableList();

    const {
        consultComercialTableProductData,
        consultComercialTableParams,
        setConsultComercialTableParams,
    } = useConsultComercialTableProductList()

    useEffect(() => {
        if (typeof comercialTableItem?.id === 'number') {
            setConsultComercialTableParams({
                ...consultComercialTableParams,
                idTabelaComercial: comercialTableItem?.id,
            })
        }
    }, [comercialTableItem?.id])

    return (
        <MainContainer>
            <div className={styles['header-wrapper']}>
                <div>
                    <SelectUpdatedHeader
                        isPending={comercialTableItem?.revisaoPendente}
                        date={comercialTableItem?.aplicadoEm}
                        value={comercialTableItem?.id}
                        data={comercialTableHeaderData}
                    />
                    <div className={styles['subtitle-wrapper']}>
                        <p className={styles.subtitle}>As alterações são salvas automaticamente</p>
                        <LinkButton fontSize="12px">Editar regras da tabela</LinkButton>
                    </div>
                </div>
                <Button
                    className={styles['btn-consult']}
                    onClick={() => history.push('/ipa/gerenciar-tabela-comercial')}
                >
                    Editar tabela
                </Button>
            </div>
            <ResultsFoundActionArea value={23}>
                <Button className={styles['btn-download']} appearance="primary">
                    Download
                </Button>
            </ResultsFoundActionArea>
            <RetailConsultCurrentTable data={consultComercialTableProductData} />
        </MainContainer>
    );
};
