import React from 'react';
import ModalConfirm from '../../../../../../../components/ModalConfirm';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

import styles from './ModalPriceLimit.module.scss';
import { useManageComercialTableStore } from '../../ManageComercialTable.store';
import { priceAndPercentageCalc, statusPriceLimit } from '../../../../calculos';

type ModalActivateProductPromoProps = {
    show?: boolean
}

export const ModalPriceLimit = ({ show, ...props }: ModalActivateProductPromoProps) => {
    const {
        comercialTablePriceLimitAndRules,
        productItemData,
        setManageComercialTableState,
        resetManageComercialTable
    } = useManageComercialTableStore();

    const limites = comercialTablePriceLimitAndRules.limites?.[0]

    const handleCloseModal = () => {
        setManageComercialTableState('openModalPriceLimit', false)
        resetManageComercialTable('productItemData')
    }

    return (
        <ModalConfirm
            show={show}
            modalWidth="500px"
            titleFontSize="16px"
            footerAlign="end"
            title="Limite de preço"
            cancelButton="Fechar"
            className={styles['modal-price-limit']}
            onCancel={handleCloseModal}
            onHideHeaderButton={handleCloseModal}
        >
            <div className={styles.content}>
                <div
                    className={[
                        styles['percent-over'],
                        styles[statusPriceLimit(productItemData, limites) ? 'alert-status' : 'regular-status']
                    ].join(' ')}
                >
                    <div className={styles['percent-status-container']}>
                        <p className={styles.label}>
                            Percentual sobre tabela base
                        </p>
                        <div className={styles['percent-min-max']}>
                            <span className={styles.text}>Mín: {limites?.percentualMinimo}%</span>
                            <span className={styles.divider}>|</span>
                            <span className={styles.text}>Max: {limites?.percentualMaximo}%</span>
                        </div>
                    </div>
                    {statusPriceLimit(productItemData, limites) ? (
                        <ReportProblemIcon className={styles['alert-icon']} />
                    ) : null}
                </div>
                <div className={styles['price-container']}>
                    <p className={styles.text}>O preço deve ficar entre</p>
                    <div>
                        <span
                            className={styles['price-value']}
                        >
                            R$ {priceAndPercentageCalc(productItemData, limites?.percentualMinimo).toFixed(2).replace('.', ',')}
                        </span>
                        <span className={styles.text}>{' e '}</span>
                        <span
                            className={styles['price-value']}
                        >
                            R$ {priceAndPercentageCalc(productItemData, limites?.percentualMaximo).toFixed(2).replace('.', ',')}
                        </span>
                    </div>
                </div>
            </div>
        </ModalConfirm>
    );
};
