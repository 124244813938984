/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable camelcase */
import classNames from 'classnames';
import { addDays } from 'date-fns';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { MdAdd, MdDeleteOutline } from 'react-icons/md';
import {
    ButtonPrimary,
    InputBox,
    Layout,
    LayoutProps,
    LoadingSpinerArea,
    Tooltips,
} from '../../../../../components';
import {
    ChainedSegmentSelect,
    CheckPill,
    FilterDescription,
    FilterGroup,
    FilterLabel,
    FilterSeparator,
    SelectPill,
} from '../../../../../components/Filters';
import { checkIfIsLoading } from '../../../../../utils';
import { IconButton, LinkButton } from '../../../RevisaoPrecos/Components';
import { RecurrenceComponent } from '../../components';
import { RegraDePrecoTag } from '../../components/RegraDePrecoTag';
import styles from '../RegrasDePreco/RegrasDePreco.module.scss';
import {
    EditCompetitorsButton,
    EditRuleCompetitorsModal,
} from '../RegrasDePreco/components';
import { compareDates, data } from '../RegrasDePreco/lib';
import { useEditarRegraDePreco } from './useEditarRegraDePreco';

const MemoEditRuleCompetitorsModal = memo(EditRuleCompetitorsModal);

const EditarRegraDePrecoPage = ({ className, ...props }: LayoutProps) => {
    const {
        form,
        onSubmit,
        rule,

        filters: { cattegory, store, segment, ...filters },
        competitors,
    } = useEditarRegraDePreco();

    const handleCategoryChange = useCallback(
        (value: string | Array<string> | undefined, index: number) => {
            cattegory.handleChangeCattegoryType(value, index);
        },
        [cattegory],
    );

    const handleCategoryClean = useCallback(
        (key: string, index: number) => {
            cattegory.handleClean(key, index);
        },
        [cattegory],
    );

    const handleCategorySelect = useCallback(
        (key: string, value: Array<string>) => {
            cattegory.handleSelectCattegory(key, value);
        },
        [cattegory],
    );

    const handleCategorySearch = useCallback(
        (key: string, query: string) => {
            cattegory.handleSearch(key, query);
        },
        [cattegory],
    );

    const renderCategoryRow = (
        key: string,
        index: number,
        currentIndexData: Array<Record<string, unknown>>,
        hasLength: boolean,
        shouldDisplayAdd: boolean,
        isFirst: boolean,
    ) => (
        <div className={styles.innerFilterContainer} key={index}>
            <SelectPill
                name="cattegoryType"
                data={currentIndexData}
                value={key}
                searchable={false}
                onChange={(value) => handleCategoryChange(value, index)}
                onClean={() => handleCategoryClean(key, index)}
                renderMenuItem={(label, item) => (
                    <span className={styles[item.value]}>{label}</span>
                )}
            />
            {key && (
                <>
                    <FilterSeparator />
                    <CheckPill
                        value={cattegory.value[key]}
                        data={cattegory.data[key] ?? []}
                        onSearch={(query) => handleCategorySearch(key, query)}
                        onChange={(value) => handleCategorySelect(key, value)}
                        onClose={() => handleCategorySearch(key, '')}
                        isLoading={checkIfIsLoading(
                            cattegory.results[index].fetchStatus,
                        )}
                    />
                </>
            )}
            {shouldDisplayAdd && (
                <button onClick={cattegory.handleAddRow} type="button">
                    <MdAdd size={20} />
                </button>
            )}
            {!isFirst && (
                <button
                    onClick={() => cattegory.handleRemoveRow(index)}
                    type="button"
                >
                    <MdDeleteOutline size={20} />
                </button>
            )}
            {isFirst && filters.isSegmentEmpty && (
                <FilterDescription>
                    Obrigatório, selecione ao menos uma categoria ou produto
                </FilterDescription>
            )}
        </div>
    );

    const categoryRows = useMemo(() => {
        return Array.from({ length: cattegory.rows }).map((_, i) => {
            const key = cattegory.selectedCattegories[i];
            let currentIndexData = cattegory.avaliableCattegories;

            const selectedSegmentData =
                cattegory.alreadySelectedSegmentsData.find(
                    (item) => item.value === key,
                );

            if (selectedSegmentData) {
                currentIndexData = [
                    ...cattegory.avaliableCattegories,
                    selectedSegmentData,
                ].sort((a, b) => a.level - b.level);
            }

            const hasLength = !!cattegory.value[key]?.length;
            const shouldDisplayAdd =
                !cattegory.isLastRow && i === cattegory.rows - 1 && hasLength;
            const isFirst = i === 0;

            return renderCategoryRow(
                key,
                i,
                currentIndexData,
                hasLength,
                shouldDisplayAdd,
                isFirst,
            );
        });
    }, [
        cattegory,
        handleCategoryChange,
        handleCategoryClean,
        handleCategorySelect,
        handleCategorySearch,
    ]);

    return (
        <Layout className={classNames(styles.page, className)} {...props}>
            <Layout.Body asChild>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Layout.Section className={styles.name} padded>
                        <div>
                            <Layout.Title>Cadastro de regra</Layout.Title>
                            <RegraDePrecoTag
                                status={rule.status}
                                expirationDate={form.watch('expirationDate')}
                            />
                        </div>
                        <Controller
                            name="name"
                            control={form.control}
                            render={({ field, fieldState: { error } }) => (
                                <InputBox className={styles['input-box']}>
                                    <InputBox.Label htmlFor="name">
                                        Nome da Regra
                                    </InputBox.Label>
                                    <InputBox.Input {...field} />
                                    <InputBox.Error message={error?.message} />
                                </InputBox>
                            )}
                        />
                    </Layout.Section>

                    <Layout.Section className={styles.filter} padded>
                        <div className={styles['inner-header']}>
                            <h4>Defina onde aplicar a regra</h4>
                        </div>
                        <div className={styles['cattegory-container']}>
                            <ul>
                                <FilterGroup asChild>
                                    <li>
                                        <FilterLabel>Categorização</FilterLabel>
                                        <div
                                            className={
                                                styles['cattegory-container']
                                            }
                                        >
                                            {categoryRows}
                                        </div>
                                    </li>
                                </FilterGroup>

                                <FilterGroup asChild>
                                    <li>
                                        <FilterLabel>Lojas</FilterLabel>
                                        <div
                                            className={
                                                styles.innerFilterContainer
                                            }
                                        >
                                            <SelectPill
                                                data={store.select.data}
                                                value={store.select.value}
                                                onSelect={store.select.onSelect}
                                                onClean={store.select.onClean}
                                                searchable={false}
                                                cleanable={false}
                                            />
                                            {store.select.value ===
                                                'individual-store' && (
                                                <>
                                                    <FilterSeparator />
                                                    <CheckPill
                                                        {...store.check}
                                                    />
                                                    {!store.check.value
                                                        .length && (
                                                        <FilterDescription>
                                                            Selecione uma loja
                                                        </FilterDescription>
                                                    )}
                                                </>
                                            )}
                                            {store.select.value ===
                                                'all-stores' && (
                                                <FilterDescription>
                                                    Você também pode escolher
                                                    lojas específicas
                                                </FilterDescription>
                                            )}
                                        </div>
                                    </li>
                                </FilterGroup>

                                <FilterGroup asChild>
                                    <li>
                                        <FilterLabel>Segmentação</FilterLabel>
                                        <div
                                            className={
                                                styles.innerFilterContainer
                                            }
                                        >
                                            <ChainedSegmentSelect
                                                select={segment.select}
                                                check={segment.check}
                                            />
                                        </div>
                                    </li>
                                </FilterGroup>
                            </ul>
                        </div>
                    </Layout.Section>

                    <Layout.Section className={styles.rule} padded>
                        <div className={styles['inner-header']}>
                            <h4>Regra</h4>
                        </div>
                        <div>
                            <Controller
                                name="mechanismType"
                                control={form.control}
                                render={({
                                    field: { onChange, ...field },
                                    fieldState: { error },
                                }) => (
                                    <InputBox className={styles['input-box']}>
                                        <InputBox.Label>
                                            Tipo de Mecanismo
                                        </InputBox.Label>
                                        <InputBox.Select
                                            data={data.mechanismType}
                                            searchable={false}
                                            cleanable={false}
                                            placeholder="Selecione uma opção"
                                            onChange={(value) => {
                                                if (
                                                    value !== 'COMPETITIVIDADE'
                                                ) {
                                                    form.resetField(
                                                        'hasAditionalMechanism',
                                                    );
                                                    form.resetField(
                                                        'aditionalMechanismType',
                                                    );
                                                }
                                                onChange(value);
                                            }}
                                            {...field}
                                        />
                                        <InputBox.Error
                                            message={error?.message}
                                        />
                                    </InputBox>
                                )}
                            />
                            {form.watch('mechanismType') !==
                                'MARGEM_OBJETIVA' && (
                                <Controller
                                    name="factor"
                                    control={form.control}
                                    render={({
                                        field: { value, onChange },
                                        fieldState: { error },
                                    }) => (
                                        <InputBox
                                            className={styles['input-box']}
                                        >
                                            <InputBox.Label htmlFor="mechanismType">
                                                %
                                                {form.watch('mechanismType') ===
                                                'MARGEM'
                                                    ? ' Margem'
                                                    : ' Competitividade'}
                                            </InputBox.Label>
                                            <InputBox.Numeric
                                                value={value ?? 0}
                                                precision="1"
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                onChangeEvent={onChange}
                                                suffix="%"
                                            />
                                            <InputBox.Error
                                                message={error?.message}
                                            />
                                        </InputBox>
                                    )}
                                />
                            )}
                            {form.watch('mechanismType') ===
                                'COMPETITIVIDADE' && (
                                <>
                                    <Controller
                                        name="priceCompetitorIndicator"
                                        control={form.control}
                                        render={({
                                            field,
                                            fieldState: { error },
                                        }) => (
                                            <InputBox
                                                className={styles['input-box']}
                                            >
                                                <InputBox.Label htmlFor="priceCompetitorIndicator">
                                                    Preço concorrente
                                                    <Tooltips.Info tooltip="Escolha qual indicador do preço concorrente aplicar na regra de competitividade" />
                                                </InputBox.Label>
                                                <InputBox.Select
                                                    data={
                                                        data.priceCompetitorIndicator
                                                    }
                                                    placeholder="Selecione uma opção"
                                                    searchable={false}
                                                    cleanable={false}
                                                    {...field}
                                                />
                                                <InputBox.Error
                                                    message={error?.message}
                                                />
                                            </InputBox>
                                        )}
                                    />
                                    <div
                                        className={
                                            styles['wrapper-dias-concorrente']
                                        }
                                    >
                                        <Controller
                                            name="competitorDays"
                                            control={form.control}
                                            render={({
                                                field,
                                                fieldState: { error },
                                            }) => (
                                                <InputBox
                                                    className={
                                                        styles['input-box']
                                                    }
                                                >
                                                    <InputBox.Label>
                                                        Dias concorrência
                                                        <Tooltips.Info tooltip="O total de dias que deve ser considerado para trazer o preço concorrente para essa regra" />
                                                    </InputBox.Label>
                                                    <InputBox.Number
                                                        min={0}
                                                        max={180}
                                                        defaultValue="1"
                                                        {...field}
                                                    />
                                                    <InputBox.Error
                                                        message={error?.message}
                                                    />
                                                </InputBox>
                                            )}
                                        />
                                        <span>Máximo de 180 dias</span>
                                    </div>
                                </>
                            )}
                        </div>
                        {form.watch('mechanismType') === 'COMPETITIVIDADE' && (
                            <div
                                className={
                                    styles['rule-action-buttons-wrapper']
                                }
                            >
                                {!form.watch('hasAditionalMechanism') && (
                                    <>
                                        <Controller
                                            control={form.control}
                                            name="hasAditionalMechanism"
                                            render={({
                                                field: { onChange },
                                            }) => (
                                                <FilterGroup>
                                                    <LinkButton
                                                        type="button"
                                                        className={
                                                            styles[
                                                                'rule__add-btn'
                                                            ]
                                                        }
                                                        onClick={() =>
                                                            onChange(true)
                                                        }
                                                    >
                                                        + Regra adicional
                                                    </LinkButton>
                                                </FilterGroup>
                                            )}
                                        />
                                    </>
                                )}

                                {(cattegory.value.storeIds ||
                                    store.select.value === 'all-stores') && (
                                    <>
                                        <EditCompetitorsButton
                                            onClick={competitors.modal.onShow}
                                        />
                                        <MemoEditRuleCompetitorsModal
                                            modal={competitors.modal}
                                            table={competitors.table}
                                        />
                                    </>
                                )}
                            </div>
                        )}
                    </Layout.Section>

                    {form.watch('mechanismType') === 'COMPETITIVIDADE' &&
                        form.watch('hasAditionalMechanism') && (
                            <Layout.Section
                                className={styles['additional-rule']}
                                padded
                            >
                                <div className={styles['inner-header']}>
                                    <div>
                                        <h4>Regra adicional</h4>
                                        <p>
                                            No caso de não encontrar preços
                                            concorrentes para o produto, usar a
                                            seguinte regra abaixo
                                        </p>
                                    </div>
                                    <Controller
                                        control={form.control}
                                        name="hasAditionalMechanism"
                                        render={({ field: { onChange } }) => (
                                            <IconButton
                                                icon="MdDelete"
                                                onClick={() => {
                                                    onChange(false);
                                                    form.reset((values) => ({
                                                        ...values,
                                                        aditionalMechanismType:
                                                            null,
                                                    }));
                                                }}
                                                className={
                                                    styles[
                                                        'additional-rule__remove-btn'
                                                    ]
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={form.control}
                                        name="aditionalMechanismType"
                                        render={({
                                            field,
                                            fieldState: { error },
                                        }) => (
                                            <InputBox
                                                className={styles['input-box']}
                                            >
                                                <InputBox.Label>
                                                    Tipo de Mecanismo
                                                </InputBox.Label>
                                                <InputBox.Select
                                                    data={
                                                        data.aditionalMechanismType
                                                    }
                                                    searchable={false}
                                                    cleanable={false}
                                                    placeholder="Selecione uma opção"
                                                    {...field}
                                                />
                                                <InputBox.Error
                                                    message={error?.message}
                                                />
                                            </InputBox>
                                        )}
                                    />
                                    {form.watch('aditionalMechanismType') ===
                                        'MARGEM' && (
                                        <Controller
                                            name="additionalMechanismFactor"
                                            control={form.control}
                                            render={({
                                                field: { value, onChange },
                                                fieldState: { error },
                                            }) => (
                                                <InputBox
                                                    className={
                                                        styles['input-box']
                                                    }
                                                >
                                                    <InputBox.Label htmlFor="mechanismType">
                                                        % Margem
                                                    </InputBox.Label>
                                                    <InputBox.Numeric
                                                        value={value as number}
                                                        precision="1"
                                                        decimalSeparator=","
                                                        thousandSeparator="."
                                                        onChangeEvent={onChange}
                                                        suffix="%"
                                                    />
                                                    <InputBox.Error
                                                        message={error?.message}
                                                    />
                                                </InputBox>
                                            )}
                                        />
                                    )}
                                </div>
                            </Layout.Section>
                        )}

                    <Layout.Section className={styles.details} padded>
                        <div className={styles['inner-header']}>
                            <h4>Detalhes da regra</h4>
                        </div>
                        <div>
                            <div className={styles.recurrenceType__container}>
                                <Controller
                                    name="recurrenceType"
                                    control={form.control}
                                    render={({
                                        field,
                                        fieldState: { error },
                                    }) => (
                                        <InputBox
                                            className={styles['input-box']}
                                        >
                                            <InputBox.Label htmlFor="recurrenceType">
                                                Tipo de Recorrência
                                            </InputBox.Label>
                                            <InputBox.Select
                                                data={data.recurrenceTypes}
                                                searchable={false}
                                                cleanable={false}
                                                placeholder="Selecione uma opção"
                                                {...field}
                                            />
                                            <InputBox.Error
                                                message={error?.message}
                                            />
                                        </InputBox>
                                    )}
                                />
                                <RecurrenceComponent
                                    recurrenceType={form.watch(
                                        'recurrenceType',
                                    )}
                                    control={form.control}
                                />
                            </div>
                            <Controller
                                name="minimumVariation"
                                control={form.control}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error },
                                }) => (
                                    <InputBox
                                        className={styles.minimumVariation}
                                    >
                                        <InputBox.Label htmlFor="minimumVariation">
                                            Variação mínima para a troca de
                                            preços
                                            <Tooltips.Info tooltip="Ao preencher esse campo você faz com que os seus preços sejam alterados apenas à partir de um percentual mínimo, ou seja, você pode utilizá-lo para evitar muitas trocas para pequenas alterações no preço." />
                                        </InputBox.Label>
                                        <InputBox.Numeric
                                            value={value}
                                            precision="1"
                                            decimalSeparator=","
                                            thousandSeparator="."
                                            onChangeEvent={onChange}
                                            suffix="%"
                                        />
                                        <InputBox.Error
                                            message={error?.message}
                                        />
                                    </InputBox>
                                )}
                            />
                            <Controller
                                name="productFamily"
                                control={form.control}
                                render={({ field, fieldState: { error } }) => (
                                    <InputBox className={styles.productFamily}>
                                        <InputBox.Label>
                                            Considerar família de produtos na
                                            aplicação da regra
                                            <Tooltips.Info tooltip="Se a regra identificar um produto que faz parte de uma família, ela aplicará o mesmo preço em todos os itens da família de produtos." />
                                        </InputBox.Label>
                                        <InputBox.Toggle size="sm" {...field} />
                                        <InputBox.Error
                                            message={error?.message}
                                        />
                                    </InputBox>
                                )}
                            />
                        </div>
                    </Layout.Section>

                    <Layout.Section className={styles.validity} padded>
                        <div className={styles.validity__header}>
                            <h4>Vigência</h4>
                            <p>Defina a vigência para a regra</p>
                        </div>
                        <div>
                            <Controller
                                name="startingDate"
                                control={form.control}
                                render={({ field, fieldState: { error } }) => (
                                    <InputBox className={styles['input-box']}>
                                        <InputBox.Label htmlFor="startingDate">
                                            Início de vigência
                                        </InputBox.Label>
                                        <InputBox.DatePicker
                                            oneTap
                                            format="DD/MM/YYYY"
                                            ranges={[]}
                                            onClean={() =>
                                                form.resetField('startingDate')
                                            }
                                            locale={data.datePickerLocale}
                                            cleanable={false}
                                            disabledDate={(date) =>
                                                compareDates(
                                                    date,
                                                    addDays(new Date(), 1),
                                                )
                                            }
                                            {...field}
                                        />
                                        <InputBox.Error
                                            message={error?.message}
                                        />
                                    </InputBox>
                                )}
                            />
                            <Controller
                                name="expirationDate"
                                control={form.control}
                                render={({
                                    field: { onChange, ...field },
                                    fieldState: { error },
                                }) => (
                                    <InputBox className={styles.expirationDate}>
                                        <InputBox.Label>
                                            <Controller
                                                name="enableExpirationDate"
                                                control={form.control}
                                                render={({ field }) => (
                                                    <InputBox.Toggle
                                                        {...field}
                                                    />
                                                )}
                                            />
                                            Final de vigência
                                            <Tooltips.Info tooltip="Habilite e defina uma vigência final caso opte por uma regra com prazo final" />
                                        </InputBox.Label>
                                        <InputBox.DatePicker
                                            oneTap
                                            format="DD/MM/YYYY"
                                            ranges={[]}
                                            onChange={onChange}
                                            onClean={() => onChange(undefined)}
                                            locale={data.datePickerLocale}
                                            disabledDate={(date) =>
                                                compareDates(
                                                    date,
                                                    form.watch('startingDate'),
                                                )
                                            }
                                            disabled={
                                                !form.watch(
                                                    'enableExpirationDate',
                                                )
                                            }
                                            {...field}
                                        />
                                        <InputBox.Error
                                            message={error?.message}
                                        />
                                    </InputBox>
                                )}
                            />
                        </div>
                    </Layout.Section>

                    <Layout.Section className={styles.footer} padded>
                        <ButtonPrimary
                            type="submit"
                            disabled={!filters.isFilterValid}
                        >
                            editar regra
                        </ButtonPrimary>
                    </Layout.Section>
                </form>
            </Layout.Body>
            <LoadingSpinerArea area="ipa/rule/edit" height="100%" size="md" />
        </Layout>
    );
};

export default React.memo(EditarRegraDePrecoPage);
