import { useMutation, useQuery } from '@tanstack/react-query';
import * as service from '../services';
import { useManageComercialTableStore } from '../ManageComercialTable.store';
import { NotificationMessage } from '../../../../../../components';

export const useComercialPriceApply = () => {
    const {
        comercialPriceApplyList,
        comercialTableItem,
    } = useManageComercialTableStore();

    const priceApplySuccess = () => {
        NotificationMessage(
            'success',
            5000,
            'Preço aplicado com sucesso',
        );
    }

    const mutation = useMutation({
        mutationFn: () => service.applyComercialPrices(comercialPriceApplyList),
        onSuccess: priceApplySuccess,
    });

    const mutationAll = useMutation({
        mutationFn: () => service.selectAllComercialAplicarPrecos(comercialTableItem?.id),
        onSuccess: priceApplySuccess,
    });

    return {
        handleConfirmPriceApply: mutation.mutateAsync,
        handleConfirmPriceApplyAll: mutationAll.mutateAsync,
    };
};
