export const dataVideoModuleReportModal = [
    {
        link: (
            <iframe
                width="100%"
                height="524"
                src="https://www.youtube.com/embed/TBKcaf82Vhk?&autoplay=1"
                title="Conheça o Módulo de Relatórios"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
            />
        ),
    },
];

export const dndProductItems = [
    {
        id: 1,
        name: 'Categoria',
        column: '1',
        hasList: true,
        search: 'Digite ou busque a categoria desejada',
        selectType: 'check',
    },
    {
        id: 2,
        name: 'Fabricante',
        column: '1',
        hasList: true,
        search: 'Digite ou busque o fabricante desejado',
        selectType: 'check',
    },
    {
        id: 3,
        name: 'Marca',
        column: '1',
        hasList: false,
        search: 'Digite ou busque a marca desejada',
        selectType: 'check',
    },
    {
        id: 4,
        name: 'Produto',
        column: '1',
        hasList: true,
        search: 'Digite ou busque o produto desejado',
        selectType: 'check',
    },
    {
        id: 5,
        name: 'Seção',
        column: '1',
        hasList: true,
        search: 'Digite ou busque a seção desejada',
        selectType: 'check',
    },
    {
        id: 6,
        name: 'Tipo de produto',
        column: '1',
        hasList: true,
        search: 'Digite ou busque o tipo de produto desejado',
        selectType: 'check',
    },
    {
        id: 7,
        name: 'Conteúdo',
        column: '1',
        hasList: false,
        search: '',
        selectType: 'check',
    },
];

export const dndLocalItems = [
    {
        id: 1,
        name: 'Cidade',
        column: '1',
        hasList: true,
        search: 'Digite ou busque a cidade desejada',
        selectType: 'check',
    },
    {
        id: 2,
        name: 'Região',
        column: '1',
        hasList: false,
        search: 'Digite ou busque a região desejada',
        selectType: 'check',
    },
    {
        id: 3,
        name: 'Tipo de Loja',
        column: '1',
        hasList: true,
        search: 'Digite ou busque o tipo de loja desejado',
        selectType: 'check',
    },
    {
        id: 4,
        name: 'UF',
        column: '1',
        hasList: true,
        search: 'Digite ou busque a UF desejada',
        selectType: 'check',
    },
    {
        id: 5,
        name: 'Loja',
        column: '1',
        hasList: false,
        search: 'Digite ou busque a loja desejada',
        selectType: 'check',
    },
    {
        id: 6,
        name: 'Rede',
        column: '1',
        hasList: true,
        search: 'Digite ou busque a rede desejada',
        selectType: 'check',
    },
    {
        id: 7,
        name: 'CNPJ da Loja',
        column: '1',
        hasList: false,
        search: 'Digite ou busque o CNPJ da loja desejada',
        selectType: 'check',
    },
    {
        id: 8,
        name: 'Endereço',
        column: '1',
        hasList: true,
        search: 'Digite ou busque o endereço desejado',
        selectType: 'select',
    },
];

export const dndPriceItems = [
    {
        id: 1,
        name: 'Preço mínimo',
        column: '1',
        hasList: false,
        selectType: 'check',
    },
    {
        id: 2,
        name: 'Preço máximo',
        column: '1',
        hasList: false,
        selectType: 'check',
    },
    {
        id: 3,
        name: 'Preço mais frequente',
        column: '1',
        hasList: false,
        selectType: 'check',
    },
    {
        id: 4,
        name: 'Preço médio',
        column: '1',
        hasList: false,
        selectType: 'check',
    },
    {
        id: 5,
        name: 'Origem do preço',
        column: '1',
        hasList: true,
        search: 'Digite ou busque a origem do preço desejada',
        selectType: 'check',
    },
    {
        id: 6,
        name: 'Último preço',
        column: '1',
        hasList: false,
        selectType: 'check',
    },
];

export const dndQuantityItems = [
    {
        id: 1,
        name: 'Qtd de Dados',
        column: '1',
        hasList: false,
        selectType: 'check',
    },
    {
        id: 2,
        name: 'Qtd de Lojas',
        column: '1',
        hasList: false,
        selectType: 'check',
    },
    {
        id: 3,
        name: 'Qtd de Redes',
        column: '1',
        hasList: false,
        selectType: 'check',
    },
];

export const dndWithFiltersItems = [
    {
        id: 1,
        name: 'Flag promoção',
        column: '1',
        hasList: true,
        search: 'Digite ou busque a flag promoção desejada',
        selectType: 'check',
    },
    {
        id: 2,
        name: 'Tipo promoção',
        column: '1',
        hasList: true,
        search: 'Digite ou busque o tipo promoção desejado',
        selectType: 'check',
    },
    {
        id: 3,
        name: 'Detalhes anúncio',
        column: '1',
        hasList: false,
        selectType: 'check',
    },
];

export const filtrosDataType = {
    Categoria: 'categoria',
    Fabricante: 'fabricante',
    Marca: 'marca',
    Seção: 'secao',
    Produto: 'produto',
    'Tipo de produto': 'tipoProduto',
    'Tipo de Loja': 'tipoLoja',
    'Tipo promoção': 'tipoPromocao',
    'Flag promoção': 'flagPromocao',
    Cidade: 'cidade',
    'CNPJ da Loja': 'cnpjLoja',
    'Origem do preço': 'origemPreco',
    UF: 'uf',
    Loja: 'loja',
    Rede: 'rede',
    Região: 'regiao',
    Endereço: 'endereco',
};

export const periodRange = ['7', '15', '30', '60', '90'];
export const brokenPeriodRange = ['Dias', 'Semanas', 'Meses', 'Todo período'];
export const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sáb',
    ok: 'Aplicar',
};

export const flagData = [
    {
        label: 'SIM',
        value: 'SIM',
    },
    {
        label: 'NÃO',
        value: 'NAO',
    },
];

export const moduleReportEnumsType = {
    Categoria: 'CATEGORIA',
    Fabricante: 'FABRICANTE',
    Marca: 'MARCA',
    Seção: 'SECAO',
    Produto: 'GTIN',
    'Tipo de produto': 'TIPO_PRODUTO',
    'Tipo de Loja': 'TIPO_LOJA',
    'Tipo promoção': 'TIPO_PROMOCAO',
    Cidade: 'CIDADE',
    'CNPJ da Loja': 'CNPJ',
    'Origem do preço': 'ORIGEM_PRECO',
    'Flag promoção': 'FLAG_PROMOCAO',
    Endereço: 'LOGRADOURO',
    UF: 'UF',
    Loja: 'LOJA',
    Rede: 'REDE',
    Região: 'REGIAO',
    Sim: 'SIM',
    Não: 'NAO',
    'Qtd de Dados': 'QUANTIDADE_DADOS',
    'Qtd de Lojas': 'QUANTIDADE_LOJAS',
    'Qtd de Redes': 'QUANTIDADE_REDES',
    'Preço mínimo': 'PRECO_MINIMO',
    'Preço máximo': 'PRECO_MAXIMO',
    'Preço mais frequente': 'PRECO_MAIS_FREQUENTE',
    'Preço médio': 'PRECO_MEDIO',
    OFERTA: 'OFERTA',
    'DE/POR': 'DE_POR',
    'DESCONTO NF': 'DESCONTO_NF',
    MARKETPLACE: 'MARKETPLACE',
    'PONTO DE VENDA': 'PONTO_DE_VENDA',
    'NOTA FISCAL': 'NOTA_FISCAL',
    'Detalhes anúncio': 'ANUNCIO_FLAG',
    Conteúdo: 'CONTEUDO_GRAMATURA',
    'Último preço': 'ULTIMO_PRECO',
    'CANAL DIGITAL': 'CANAL_DIGITAL',
    'CANAL FISICO': 'CANAL_FISICO',
};

export const moduleReportReverseEnumsType = {
    CATEGORIA: 'Categoria',
    FABRICANTE: 'Fabricante',
    MARCA: 'Marca',
    SECAO: 'Seção',
    GTIN: 'Produto',
    TIPO_PRODUTO: 'Tipo de produto',
    TIPO_LOJA: 'Tipo de Loja',
    TIPO_PROMOCAO: 'Tipo promoção',
    CIDADE: 'Cidade',
    CNPJ: 'CNPJ da Loja',
    ORIGEM_PRECO: 'Origem do preço',
    FLAG_PROMOCAO: 'Flag promoção',
    LOGRADOURO: 'Endereço',
    UF: 'UF',
    LOJA: 'Loja',
    REDE: 'Rede',
    REGIAO: 'Região',
    QUANTIDADE_DADOS: 'Qtd de Dados',
    QUANTIDADE_LOJAS: 'Qtd de Lojas',
    QUANTIDADE_REDES: 'Qtd de Redes',
    PRECO_MINIMO: 'Preço mínimo',
    PRECO_MAXIMO: 'Preço máximo',
    PRECO_MAIS_FREQUENTE: 'Preço mais frequente',
    PRECO_MEDIO: 'Preço médio',
    ANUNCIO_FLAG: 'Detalhes anúncio',
    CONTEUDO_GRAMATURA: 'Conteúdo',
    ULTIMO_PRECO: 'Último preço',
};

export const raioList = [
    {
        label: '2 KM',
        value: 2,
    },
    {
        label: '3 KM',
        value: 3,
    },
    {
        label: '4 KM',
        value: 4,
    },
    {
        label: '5 KM',
        value: 5,
    },
    {
        label: '6 KM',
        value: 6,
    },
    {
        label: '7 KM',
        value: 7,
    },
    {
        label: '8 KM',
        value: 8,
    },
    {
        label: '9 KM',
        value: 9,
    },
    {
        label: '10 KM',
        value: 10,
    },
];

export const downloadFile = (url, fileName) => {
    const hiddenElement = document.createElement('a');
    hiddenElement.target = '_blank';
    hiddenElement.href = url;
    hiddenElement.download = fileName;
    hiddenElement.click();
};

const insertZeroInDateString = (number) => (number > 9 ? number : `0${number}`);

export const handleDateFormat = (date) => {
    const dt = new Date(date);
    const year = dt.getUTCFullYear();
    const month = dt.getUTCMonth() + 1;
    const day = dt.getUTCDate();

    return `${insertZeroInDateString(day)}/${insertZeroInDateString(
        month,
    )}/${year}`;
};

export const noConsolidateList = [
    'QUANTIDADE_DADOS',
    'QUANTIDADE_LOJAS',
    'QUANTIDADE_REDES',
    'PRECO_MINIMO',
    'PRECO_MAXIMO',
    'PRECO_MAIS_FREQUENTE',
    'PRECO_MEDIO',
    'ULTIMO_PRECO',
];

export const dimensionsSelectionMissingText =
    'Defina pelo menos 1 (um) elemento em todas as dimensões para gerar o relatório.';

export const productOptionsNotSelectedText =
    'Não foi possível realizar essa extração pois o tamanho limite do arquivo foi excedido. Realize a seleção de filtros em pelo menos 1 dos elementos da dimensão produto.';

export const reportNameAndInitialDateMissingText =
    'Os campos Nome do relatório e Próximo relatório não podem ficar vazios';

export const dailyFrequency = [
    {
        label: 'De segunda a sexta-feira',
        value: 'WEEKLY',
    },
    {
        label: 'Todos os dias',
        value: 'EVERY',
    },
];

export const weeklyFrequency = [
    {
        label: 'Segunda-feira',
        value: 'SEG',
    },
    {
        label: 'Terça-feira',
        value: 'TER',
    },
    {
        label: 'Quarta-feira',
        value: 'QUA',
    },
    {
        label: 'Quinta-feira',
        value: 'QUI',
    },
    {
        label: 'Sexta-feira',
        value: 'SEX',
    },
    {
        label: 'Sábado',
        value: 'SAB',
    },
    {
        label: 'Domingo',
        value: 'DOM',
    },
];

export const dailyFrequencyEnumsType = {
    WEEKLY: 'De segunda a sexta-feira',
    EVERY: 'Todos os dias',
    DIARIA: 'Diária',
    SEMANAL: 'Semanal',
    SEG: 'segunda-feira',
    TER: 'terça-feira',
    QUA: 'quarta-feira',
    QUI: 'quinta-feira',
    SEX: 'sexta-feira',
    SAB: 'sábado',
    DOM: 'domingo',
};

export const getDateIntervals = (dateA, dateB) => {
    const initialDate = new Date(dateA);
    const endDate = new Date(dateB);
    const diffDays =
        parseInt((endDate - initialDate) / (1000 * 60 * 60 * 24), 10) + 1;

    const isSpecificDates =
        diffDays !== 7 &&
        diffDays !== 15 &&
        diffDays !== 30 &&
        diffDays !== 60 &&
        diffDays !== 90;

    return { isSpecificDates, diffDays };
};

export const brokenPeriodRangeEnumType = {
    DIAS: 'Dias',
    SEMANAS: 'Semanas',
    MESES: 'Meses',
    TODO_PERIODO: 'Todo período', 
};

export const moduleReportReverseModelType = {
    CATEGORIA: 'categoria',
    FABRICANTE: 'fabricante',
    MARCA: 'marca',
    SECAO: 'secao',
    GTIN: 'produto',
    TIPO_PRODUTO: 'tipoProduto',
    TIPO_LOJA: 'tipoLoja',
    TIPO_PROMOCAO: 'tipoPromocao',
    CIDADE: 'cidade',
    CNPJ: 'cnpjLoja',
    ORIGEM_PRECO: 'origemPreco',
    FLAG_PROMOCAO: 'flagPromocao',
    LOGRADOURO: 'endereco',
    UF: 'uf',
    LOJA: 'loja',
    REDE: 'rede',
    REGIAO: 'regiao',
    QUANTIDADE_DADOS: 'quantidadeDados',
    QUANTIDADE_LOJAS: 'quantidadeLojas',
    QUANTIDADE_REDES: 'quantidadeRedes',
    PRECO_MINIMO: 'precoMinimo',
    PRECO_MAXIMO: 'precoMaximo',
    PRECO_MAIS_FREQUENTE: 'precoFrequente',
    PRECO_MEDIO: 'precoMedio',
    ANUNCIO_FLAG: 'detalhesAnuncio',
    CONTEUDO_GRAMATURA: 'conteudo',
    ULTIMO_PRECO: 'ultimoPreco',
};

export const tipoPromocaoEnumValue = {
    OFERTA: 'OFERTA',
    DE_POR: 'DE/POR',
    DESCONTO_NF: 'DESCONTO NF',
};

export const setLocalDateISOFormat = (date) => {
    const tzoffset = date.getTimezoneOffset() * 60000;
    const localISOTime = new Date(date - tzoffset).toISOString().slice(0, -1);

    return localISOTime;
};
